import React, { Component } from "react";
import "./page2.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import rightArrow from "../images/rightArrow.png";
import $ from "jquery";
import { connect } from "react-redux";
let arrLength = 0;
let zestlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572085250/Projects/Zest/zest_vaclpd.png";

let img1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/1_mojjeh.jpg";
let img2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/2_of4c6c.jpg";
let img3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/4_y8sftd.jpg";
let img4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/5_sazjam.jpg";

let balcony =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113450/Projects/Zest/Amenities/Balcony_-_White_ohyx2d.svg";
let wifi =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113450/Projects/Zest/Amenities/Wi-Fi_-_White_ok0dya.svg";
let Safety_Railings =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113448/Projects/Zest/Amenities/Safety_Railings_-_White_idlyqc.svg";
let Pocket_Terrace =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113448/Projects/Zest/Amenities/Pocket_Terrace_-_White_mfqz9i.svg";
let NaturallyLitLobby =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113448/Projects/Zest/Amenities/Naturally-Lit_Lobby_-_White_oaruwt.svg";
let Rooftop_Garden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113448/Projects/Zest/Amenities/Rooftop_Garden_-_White_br0yof.svg";
let Interntional_Sanitaryware =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113448/Projects/Zest/Amenities/Interntional_Sanitaryware_-_White_uysgsi.svg";
let Housekeeping =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113447/Projects/Zest/Amenities/Housekeeping_-_White_hdplor.svg";
let Covered_Parking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113446/Projects/Zest/Amenities/Covered_Parking_-_White_ciewns.svg";
let Centralised_Cable_TV =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113446/Projects/Zest/Amenities/Centralised_Cable_TV_-_White_ddbphr.svg";
let High_speed_elevator =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113446/Projects/Zest/Amenities/High-Speed_Elevators_-_White_u3rdeg.svg";
let French_Windows =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113446/Projects/Zest/Amenities/French_Windows_-_White_bx7gng.svg";
let CCTV_Cameras =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113446/Projects/Zest/Amenities/CCTV_Cameras_-_White_ok9ikj.svg";
let Car_Lift =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572113445/Projects/Zest/Amenities/Car_Lift_-_White_gostev.svg";
let sliderImage = [];
var animtime = 750;
var mobAnimtime = 50;
class Page2 extends Component {
  state = {
    slideImg: [img1, img2, img3, img4],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Pocket_Terrace,
      NaturallyLitLobby,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      Centralised_Cable_TV,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift
    ],
    arr: "",
    arr2: []
  };
  renderCount = () => {
    this.props.projects.map((item, index) => {
      if (this.props.activeProject === item.title) {
        if (item.amenities.length <= 10) {
          $(".rightArrow").hide();
          $(".leftArrow").hide();
          return this.setState({ arr: item.amenities.length });
        }
      }
    });
  };
  renderSliderImages = () => {
    this.setState({ arr2: [] });
    this.props.projects.map((item, index) => {
      if (item.title === this.props.activeProject) {
        return item.slideImg.map((slideImg, index) => {
          // return sliderImage.push(slideImg);
          return this.setState(prevState => ({
            arr2: [...prevState.arr2, slideImg]
          }));
        });
      }
    });
  };
  componentDidMount() {
    this.renderSliderImages();
    this.renderCount();
    if ($(window).width() < 800) {
      // $("#prv-testimonial").on("click", function() {
      //   var $last = $("#testimonial-list li:last");
      //   $last.remove().css({ "margin-left": "-50vw" });
      //   $("#testimonial-list li:first").before($last);
      //   $last.css("margin-left", "0px");
      // });
      // $("#nxt-testimonial").on("click", function() {
      //   var $first = $("#testimonial-list li:first");
      //   $first.css("margin-left", "-50vw");
      //   $first.remove().css({ "margin-left": "0px" });
      //   $("#testimonial-list li:last").after($first);
      // });
    } else {
      $("#prv-testimonial").on("click", function () {
        var $last = $("#testimonial-list li:last");
        $last.remove().css({ "margin-left": "-41.2vw" });
        $("#testimonial-list li:first").before($last);
        $last.animate({ "margin-left": "0px" }, animtime);
      });

      $("#nxt-testimonial").on("click", function () {
        var $first = $("#testimonial-list li:first");
        $first.animate({ "margin-left": "-41.2vw" }, animtime, function () {
          $first.remove().css({ "margin-left": "0px" });
          $("#testimonial-list li:last").after($first);
        });
      });
    }
    // $(".rightArrow").click(function(event) {
    //   var pos = $(".iconParentContainer").scrollLeft() + 460;
    //   $(".iconParentContainer").scrollLeft(pos);
    //   $(".rightArrow").css("opacity", "0.5");
    //   $(".rightArrow").css("cursor", "unset");
    //   $(".leftArrow").css("cursor", "pointer");
    //   $(".leftArrow").css("opacity", "1");
    // });

    // $(".leftArrow").click(function(event) {
    //   var pos = $(".iconParentContainer").scrollLeft() - 460;
    //   $(".iconParentContainer").scrollLeft(pos);
    //   $(".rightArrow").css("opacity", "1");
    //   $(".rightArrow").css("cursor", "pointer");
    //   $(".leftArrow").css("cursor", "unset");
    //   $(".leftArrow").css("opacity", "0.5");
    // });
  }
  next = () => {
    if ($(window).width() < 800) {
      var $first = $("#testimonial-list li:first");
      $first.css("margin-left", "-80vw");
      $first.remove().css({ "margin-left": "0px" });
      $("#testimonial-list li:last").after($first);
    }
  };
  prev = () => {
    if ($(window).width() < 800) {
      var $last = $("#testimonial-list li:last");
      $last.css({ "margin-left": "-80vw" });
      $("#testimonial-list li:first").before($last);
      $last.css("margin-left", "0px");
    }
  };
  render() {
    const renderSlider = () => {
      return (
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          interval={3000}
          transitionTime={1000}
          swiping
          swipeable
          showIndicators={true}
          width="57.29167vw;"
          infiniteLoop={true}
        >
          {/* {this.props.projects.map((item, index) => {
            if (this.props.activeProject === item.title) {
              return item.slideImg.map((slideImg, index) => {
                return (
                  <div class="slideimage">
                    <img src={slideImg} alt="" />
                  </div>
                );
              });
            }
          })} */}
          {this.state.arr2.map((item, index) => {
            return (
              <div class="slideimage">
                <img src={item} alt="" />
              </div>
            );
          })}
        </Carousel>
      );
    };

    const renderAmenities = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            return <img src={amenities} alt="" key={index} />;
          });
        }
      });
    };
    const renderAmenities2 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index < 10) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };
    const renderAmenities3 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index >= 10 && index < 20) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };
    const renderAmenities4 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index >= 20 && index < 30) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };

    //Mobile Amenities
    const renderMobAmenities2 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index < 8) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };
    const renderMobAmenities3 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index >= 8 && index < 16) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };
    const renderMobAmenities4 = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return item.amenities.map((amenities, index) => {
            if (index >= 16 && index < 24) {
              return <img src={amenities} alt="" key={index} />;
            }
          });
        }
      });
    };
    // ends here
    const renderInfo = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return (
            <React.Fragment>
              <div className="projectHeader">
                <img src={item.logo} alt="" />
              </div>
              <div className="projectDesc">{item.desc}</div>
              <div className="projectDesc">{item.neighbourDesc}</div>
            </React.Fragment>
          );
        }
      });
    };
    const renderNeighbourInfo = () => {
      return this.props.projects.map((item, index) => {
        if (this.props.activeProject === item.title) {
          return <p> {item.neighbourDesc}</p>;
        }
      });
    };
    return (
      <div className={"page2Container " + this.props.className} style={this.props.style}>
        <div className="leftSection">
          <div className="sliderSection">{renderSlider()}</div>
          {/* <div className="neighbourSection">{renderNeighbourInfo()}</div> */}
        </div>

        <div className="rightSection">
          {renderInfo()}
          {/* <div className="neighbourrInfo">
            {$(window).width() <= 800 ? renderNeighbourInfo() : ""}
          </div> */}

          <div id="amenitiesContainer">
            <p>Amenities</p>

            <div class="carousel-wrap">
              <ul id="testimonial-list" className="clearfix">
                {$(window).width() < 800
                  ? this.props.projects.map((item, index) => {
                    if (this.props.activeProject === item.title) {
                      if (item.amenities.length <= 8) {
                        return (
                          <>
                            <li className="container1">
                              {renderAmenities()}
                            </li>
                          </>
                        );
                      } else if (
                        item.amenities.length <= 16 &&
                        item.amenities.length > 8
                      ) {
                        return (
                          <>
                            <li className="container1">
                              {renderMobAmenities2()}
                            </li>
                            <li className="container2">
                              {renderMobAmenities3()}
                            </li>
                          </>
                        );
                      } else if (
                        item.amenities.length > 16 &&
                        item.amenities.length <= 24
                      ) {
                        return (
                          <>
                            <li className="container1">
                              {renderMobAmenities2()}
                            </li>
                            <li className="container2">
                              {renderMobAmenities3()}
                            </li>
                            <li className="container3">
                              {renderMobAmenities4()}
                            </li>
                          </>
                        );
                      }
                    }
                  })
                  : this.props.projects.map((item, index) => {
                    if (this.props.activeProject === item.title) {
                      console.log(item.amenities.length);
                      if (item.amenities.length <= 10) {
                        return (
                          <>
                            <li className="container1">
                              {renderAmenities()}
                            </li>
                          </>
                        );
                      } else if (
                        item.amenities.length <= 20 &&
                        item.amenities.length > 10
                      ) {
                        return (
                          <>
                            <li className="container1">
                              {renderAmenities2()}
                            </li>
                            <li className="container2">
                              {renderAmenities3()}
                            </li>
                          </>
                        );
                      } else if (
                        item.amenities.length > 20 &&
                        item.amenities.length <= 30
                      ) {
                        return (
                          <>
                            <li className="container1">
                              {renderAmenities2()}
                            </li>
                            <li className="container2">
                              {renderAmenities3()}
                            </li>
                            <li className="container3">
                              {renderAmenities4()}
                            </li>
                          </>
                        );
                      }
                    }
                  })}
                { }
              </ul>
            </div>
            <div class="arrow-Container">
              <img
                src={rightArrow}
                id="prv-testimonial"
                class="leftArrow"
                onClick={() => this.prev()}
              />
              <img
                src={rightArrow}
                id="nxt-testimonial"
                class="rightArrow"
                onClick={() => this.next()}
              />
            </div>
          </div>

          {/* <div className="amenitiesContainer">
            <p>Amenities</p>

            <img src={rightArrow} alt="" className="leftArrow" />

            <img src={rightArrow} alt="" className="rightArrow" />

            <div className="iconParentContainer">
              {this.props.projects.map((item, index) => {
                if (this.props.activeProject === item.title) {
                  if (item.amenities.length <= 10) {
                    return (
                      <div className="iconChildContainer">
                        {renderAmenities()}
                      </div>
                    );
                  } else if (item.amenities.length <= 20) {
                    return (
                      <div className="iconChildContainer2">
                        <div className="container1">{renderAmenities2()}</div>
                        <div className="container2">{renderAmenities3()}</div>
                      </div>
                    );
                  } else if (item.amenities.length <= 30) {
                    return (
                      <div className="iconChildContainer2">
                        <div className="container1">{renderAmenities2()}</div>
                        <div className="container2">{renderAmenities3()}</div>
                        <div className="container3">{renderAmenities4()}</div>
                      </div>
                    );
                  }
                }
              })}
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    // activeProject: state.activeProject,
    activeMap: state.activeMap,
    activeThumb: state.activeThumb
  };
};

const mapDispatchToProps = dispatch => {
  return {
    thumbClick: name => dispatch({ type: "thumbClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page2);
