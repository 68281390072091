import React, { Component } from "react";
import "./project.css";
let img10 =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1563452887/Maheshwari/headerslide/61_gsup7z.jpg";
class Project extends Component {
  state = {};
  render() {
    return (
      <div className="project-container ani-show">
        <div className="image-container">
          <img className="image-disp" src={this.props.titleImg} />
          <div className="info-holder">
            {this.props.hover.map(item => {
              return (
                <React.Fragment>
                  <img
                    src={item.logo}
                    alt={item.projectName}
                    className="projectLogo"
                  />
                  <div className="name">{item.projectName}</div>
                  <div className="progress">{item.progress}</div>
                </React.Fragment>
              );
            })}
            {/* <div className="title">{this.props.hover}</div> */}
          </div>
        </div>
        {/* <div className="project-title">{this.props.title}</div> */}
      </div>
    );
  }
}

export default Project;
