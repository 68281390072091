import React, { Component } from "react";
import AboutUs1css from "./aboutus4.css";
import team from "../images/team.png";
import Background from "../Background/Background";
import Links from "../links/Links";
import { nextPage } from "../animation/Animation";
import P from "../p/P";
import prev from "../images/prev.png";
import $ from "jquery";
import { connect } from "react-redux";
// import P from "../p/P";
let vinay =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1567495084/Vinay_ogqjqd.jpg";
let hitest =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566371868/hitesh_jain_1_zg3zhl.jpg";
class AboutUs4 extends Component {
  state = {};
  componentDidMount() {
    $(".teamHeader p:first-child").addClass("activeTeam");

    // if ($(window).width() < 800) {
    //   $(".team-image img").click(function() {
    //     // $(this).css("height", 194.5 * (100 / 375) + "vw");
    //     // $(this).css("width", 140.5 * (100 / 375) + "vw");
    //     $(".teamHeader p").removeClass("activeTeam");
    //     $(this).addClass("activeTeam");
    //   });
    // }
    $(".teamHeader p").click(function () {
      $(".teamHeader p").removeClass("activeTeam");
      $(this).addClass("activeTeam");
    });
  }
  teamToggle(name) {
    $(".activeTeam").css("display", "none");
    $("." + name).css("display", "block");
  }
  render() {
    return (
      <div className={"aboutus04-container " + this.props.className}>
        <div className="backSection" onClick={() => nextPage("03")}>
          <img src={prev} alt="" className="backBtn" />
          Back
        </div>
        {/* <Background
          title="Sustainability"
          bgLine={false}
          downArrow={true}
          className="textAdjust"
        /> */}
        <Links activeLink={"Sustainability"} />

        <div className="panelContainer">
          <div className="section-1" />
          <div className="section-2">
            <P
              className="p-adjust"
              p="We, at Sambhav, practice sustainability within our workforce, and through our work as well. From using sustainable materials for our buildings to encouraging the use of natural resources such as natural light, recycling, etc. vis-à-vis our designs, we take to downsizing our carbon footprint seriously. "
            />
            <P
              className="p-adjust"
              p="Keeping insulated concrete walls, in most of our buildings, which are both exceptionally strong and energy-efficient. We try to include green roofs (Rooftop gardens), in our buildings."
            />
            <P
              className="p-adjust"
              p="We cannot ignore the reality of the water shortage that Mumbai is going to face shortly soon. Thus, in terms of saving water and energy, we have fixed water-efficient plumbing fixtures, wastewater treatment, rainwater harvesting facilities, water metering as well as energy metering. "
            />
            <P
              className="p-adjust"
              p="With a massive stockpile of trash building up in this country, we believe in helping solve the issue­­­ by making our customers segregate waste that is produced from their Sambhav homes."
            />
            <P
              className="p-adjust"
              p="For our electric car-owning consumers, we have included electric car charging points in many of our buildings."
            />
          </div>
          <div className="section-3" />
        </div>
        <div className="arrow-header">Core Team</div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    team: state.team,
    activeTeam: state.activeTeam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    teamClick: name => dispatch({ type: "teamClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs4);
