import $ from "jquery";
let curr = "01";
let activeMainPage = "";
let activeSection = "01";
export function entryanimation(currentPage, sectionNumber) {
  activeMainPage = currentPage;
  activeSection = sectionNumber;
  //   alert("currentPage " + activeMainPage + " activeSection " + activeSection);
  $(
    "." + activeMainPage + "" + "0" + parseInt(sectionNumber) + "-container "
  ).css("display", "block");
  setTimeout(function () {
    curr = "0" + parseInt(sectionNumber);
    $(
      "." + activeMainPage + "" + "0" + parseInt(sectionNumber) + "-container "
    ).css("display", "block");
    $("." + activeMainPage + "" + curr + "-container .inactive-line .line").css(
      "width",
      35 * (100 / 1920) + "vw"
    );
    $("." + activeMainPage + "" + curr + "-container .active-line .line").css(
      "width",
      95 * (100 / 1920) + "vw"
    );
    $("." + activeMainPage + "" + curr + "-container .section-1").css(
      "height",
      "18%"
    );
    $("." + activeMainPage + "" + curr + "-container .section-3").css(
      "height",
      "100%"
    );
  }, 100);
}
export function exitanimation(activeMainPage) {
  //   $(
  //     "." + activeMainPage + "" + "0" + parseInt(sectionNumber) + "-container "
  //   ).css("display", "block");
  $("." + activeMainPage + "" + curr + "-container .inactive-line .line").css(
    "width",
    0 * (100 / 1920) + "vw"
  );
  $("." + activeMainPage + "" + curr + "-container .active-line .line").css(
    "width",
    0 * (100 / 1920) + "vw"
  );
  $("." + activeMainPage + "" + curr + "-container .section-1").css(
    "height",
    "0%"
  );
  $("." + activeMainPage + "" + curr + "-container .section-3").css(
    "height",
    "0%"
  );
  setTimeout(function () {
    // $("." + activeMainPage + "" + curr + "-container ").css("opacity", "0");
    $("." + activeMainPage + "" + curr + "-container ").css("display", "none");
  }, 100);
}

export function nextPage(nextNumber) {
  activeSection = nextNumber;
  exitanimation(activeMainPage);
  entryanimation(activeMainPage, activeSection);
}

export function next() {
  let maxPages = 5;
  if (maxPages >= parseInt(curr) + parseInt(1)) {
    nextPage("0" + (parseInt(curr) + parseInt(1)));
  } else {
  }
}

export function previous() {
  if (1 <= parseInt(curr) - parseInt(1)) {
    nextPage("0" + (parseInt(curr) - parseInt(1)));
  } else {
  }
}

$(document).ready(function () {
  $(document).on("keydown", function (e) {
    if (e.which == "38") {
      previous();
    }
    if (e.which == "40") {
      next();
    }
  });
});

export const scrollifyy = (currPage) => {
  // currPage = "";
  var current = currPage;
  if (current === "aboutus") {
    $("body").ready(function () {
      var number = $(".number");
      var i = 0;
      var sem = 0;
      var displacement = $(window).scrollTop();

      var scroll = 0;
      $(window).on("wheel", function (e) {
        // console.log(currPage);
        if (sem == 0) {
          sem = 1;
          i++;
          console.log("Scrolll DATA " + e.originalEvent.wheelDelta >= 0);
          e.originalEvent.wheelDelta < 0 ? next() : previous();

          $.data(
            this,
            "scrollTimer",
            setTimeout(function () {
              sem = 0;
              // $(window).scrollTop($(window).scrollTop(e));
            }, 1000)
          );
        }
      });
    });
  }
};
