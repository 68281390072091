import React, { Component } from "react";
import AboutUs1css from "./aboutus3.css";
import Links from "../links/Links";
import { nextPage } from "../animation/Animation";
import prev from "../images/prev.png";

import Background from "../Background/Background";
import P from "../p/P";
class AboutUs3 extends Component {
  state = {};
  render() {
    return (
      <div className={"aboutus03-container " + this.props.className}>
        <div className="backSection" onClick={() => nextPage("02")}>
          <img src={prev} alt="" className="backBtn" />
          Back
        </div>
        <Links activeLink={"Architect & Design"} />

        {/* <Background
          title=""
          bgLine={false}
          downArrow={true}
          title="Architect &amp; Design"
        /> */}
        <div className="panelContainer">
          <div className="section-1" />
          <div className="section-2">
            <P
              className="p-adjust"
              p="For the architects at Sambhav, a project is never just a project. It is a vision."
            />
            <br />
            <P
              className="p-adjust"
              p="A vision to fullfill the needs and wants of its customers while upholding the essence of the projects’ location. "
            />{" "}
            <br />
            <P
              className="p-adjust"
              p="The architects at Sambhav seek the virtues of creativity and profitability, working in unison, to deliver homes that are grand and luxurious, yet cost-effective."
            />{" "}
            <br />
            <P
              className="p-adjust"
              p="These ideas can be seen best in The Primordial House, an ongoing project of the group. The team’s mission is to contribute to the city’s scape in a way that will only elevate its beauty and elegance. "
            />
          </div>
          <div className="section-3" />
        </div>
        <div className="arrow-header">Sustainability</div>
      </div>
    );
  }
}

export default AboutUs3;
