import React, { Component } from "react";
import Background from "../Background/Background";
import pdcss from "./projectdetail.css";
import pdlogo from "../images/pd-logo.png";
import P from "../p/P";
import Footer from "../footer/Footer";
import Highlight from "../Highlight/Highlight";
import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import H1 from "../H1/H1";
import Slider from "../slider/Slider";
import $ from "jquery";
import back from "../images/back.png";
import next from "../images/next.png";
import prev from "../images/prev.png";
import location from "../images/location.png";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { scrollifyy } from "../animation/Animation";

class ProjectDetail extends Component {
  state = {};
  obj(thumbs) {
    $(".active-img").attr("src", thumbs);
  }
  componentDidMount() {
    $("html").css("overflow-y", "auto");
    scrollifyy("projectdetail");
    $(document).scrollTop(0);
    // alert(this.props.activeCategory);
    this.props.projectClick(this.props.activeCategory);
  }
  componentDidUpdate() {
    $(document).scrollTop(0);
  }
  render() {
    const renderHighlight = () => {
      return this.props.projects.map((item, index) => {
        if (item.title == this.props.activeProject) {
          return item.highlight.map((highlight, index) => {
            return <Highlight highlight={highlight} />;
          });
        }
      });
    };
    const renderProject = () => {
      return this.props.projects.map((item, index) => {
        if (item.title == this.props.activeProject) {
          return (
            <div className="panelContainer">
              <div className="project-header">
                <div className="project-title">{item.title}</div>
                <img className="project-logo" src={pdlogo} alt="" />
                <div className="btn-container">
                  <img
                    src={next}
                    onClick={() => this.props.next()}
                    title="Next"
                  />
                  <img
                    src={prev}
                    onClick={() => this.props.prev()}
                    title="Previous"
                  />
                  <NavLink to="/portfolio">
                    <img src={back} title="Back" />
                  </NavLink>
                  {/* <img src={location} alt="" /> */}
                </div>
              </div>

              {(() => {
                if (item.desc1) {
                  return (
                    <div className="section-1">
                      <P className="p-adjust" p={item.desc1} />

                      <div className="button-container">
                        <button className="project-btn">Download PDF</button>
                        <NavLink to="/contact">
                          <button className="project-btn">Enquire</button>
                        </NavLink>
                      </div>
                    </div>
                  );
                }
              })()}

              {(() => {
                if (item.desc4) {
                  return (
                    <div className="section-1">
                      <P className="p-adjust2" p={item.desc4} />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (item.imgSrc1) {
                  return (
                    <div className="section-2">
                      <img src={item.imgSrc1} alt="" />
                    </div>
                  );
                }
              })()}
              {/* // {(()=>{
// if(){}
// })()} */}

              {(() => {
                if (item.imgSrc2) {
                  return (
                    <div className="section-3">
                      <div className="left">
                        <img src={item.imgSrc2} alt="" />
                      </div>
                      <div className="right">
                        <div className="hightlight-text"> Highlights</div>
                        {renderHighlight()}
                      </div>
                    </div>
                  );
                }
              })()}

              {(() => {
                if (item.imgSrc3) {
                  return (
                    <div className="section-2">
                      <img src={item.imgSrc3} alt="" />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (item.desc2) {
                  return (
                    <div className="section-4">
                      <div className="left">
                        <H1 h1="The Neighbourhood" />
                        <P className="p-adjust" p={item.desc2} />
                        <P className="p-adjust2" p={item.desc3} />
                      </div>
                      <div className="right">
                        {/* onClick={() => this.obj(thumbs)} */}
                        {/* {renderSlider()} */}
                        <Slider activeCategory={this.props.activeProject} />
                      </div>

                      {/* {() => { */}
                      {/* if (item.thumbs.lenght > 0) { */}
                      {/* return ( */}
                      {/* <div className="right"> */}
                      {/* onClick={() => this.obj(thumbs)} */}
                      {/* {renderSlider()} */}
                      {/* <Slider */}
                      {/* activeCategory={this.props.activeProject} */}
                      {/* /> */}
                      {/* </div> */}
                      {/* ); */}
                      {/* } else { */}
                      {/* return <React.Fragment></React.Fragment>; */}
                      {/* } */}
                      {/* }} */}
                    </div>
                  );
                }
              })()}

              <div className="project-footer">
                <Footer />
              </div>
            </div>
          );
        }
      });
    };

    return (
      <div className="projectdetail-container">
        <NavLink to="/portfolio">
          <div className="backSection">
            <img src={prev} alt="" className="backBtn" />
            Back
          </div>
        </NavLink>
        <Background />
        {renderProject()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    activeProject: state.activeProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectClick: name => dispatch({ type: "projectClick", value: name }),
    next: () => dispatch({ type: "next" }),
    prev: () => dispatch({ type: "prev" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
