import React, { Component } from "react";
import hcss from "./highlight.css";
import h1 from "../images/h1.png";
import h2 from "../images/h2.png";

class Highlight extends Component {
  state = {};
  render() {
    return (
      <div className="highlight-container">
        <img src={this.props.highlight} alt="" />
      </div>
    );
  }
}

export default Highlight;
