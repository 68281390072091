import React, { Component } from "react";
import close from "../images/close.png";
import menucss from "./menu.css";
import insta from "../images/instagram.png";
import fb from "../images/facebook.png";
import linkd from "../images/linkedin.png";
import $ from "jquery";
import { NavLink } from "react-router-dom";

class Menu extends Component {
  state = {};
  closeToggle() {
    // $("html").css("overflow-y", "auto");
    // $("body").css("overflow-y", "auto");
    $(".menu-section").removeClass("animated slideInRight animation");

    $(".menu-section").addClass("animated fadeOutRight delay-1000ms animation");

    $(".menu-section").css("opacity", "0");

    setTimeout(function () {
      $(".menu-section").css("display", "none");
    }, 500);
  }
  render() {
    return (
      <div className="Menu-container animated slideInRight delay-1000ms animation">
        {/*  animated slideInDown delay-1000ms animation */}
        <div className="close" onClick={() => this.closeToggle()}>
          <img src={close} alt="" />
          CLOSE
        </div>
        <ul>
          <NavLink activeClassName="activeRoute" to="/" exact>
            <li>Home</li>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/aboutus" exact>
            <li>About Us</li>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/portfolio">
            <li>Portfolio</li>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/press" exact>
            <li>Press Release</li>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/contact" exact>
            <li>Contact Us</li>
          </NavLink>
        </ul>
        <div className="menu-logo">
          <a href="https://www.instagram.com/sambhavgroup" target="_blank">
            <img src={insta} alt="" />
          </a>
          <a href="https://www.facebook.com/Sambhavgroup9" target="_blank">
            <img src={fb} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/sambhav-group/"
            target="_blank"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="#">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i class="fab fa-pinterest-p"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Menu;
