import React, { Component } from "react";
import AboutUs from "./aboutus/AboutUs";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutUs1 from "./aboutus1/AboutUs1";
import Contact from "./contact/Contact";
import Portfolio from "./portfolio/Portfolio";
import ProjectDetail from "./projectdetail/ProjectDetail";
import Home2 from "./home2/Home2";
import Loading from "./loading/Loading";
import { connect } from "react-redux";
import Press from "./press/Press";
import ProjectPage from "./projectPage/ProjectPage";

class Main extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home2} exact />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/contact" component={Contact} />
          <Route path="/press" component={Press} />
          <Route path="/portfolio" component={Portfolio} exact />
          <Route
            path="/portfolio/:project"
            exact
            render={({ match }) => {
              return <ProjectPage activeCategory={match.params.project} />;
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeProject: state.activeProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectClick: name => dispatch({ type: "projectClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
