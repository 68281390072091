import React, { Component } from "react";
import bgcss from "./home.css";
import home from "../images/home-bg.gif";
import whiteLogo from "../images/whiteLogo.png";
import Background from "../Background/Background";
import search from "../images/search.png";
import fb from "../images/fbk.png";
import insta from "../images/ins.png";
import lnkd from "../images/lnkd.png";
import $ from "jquery";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
let eden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/Eden_Logo_C-8_hsvcmq.png";
let tiara =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1582089786/projectLogo/Tiara_Logo_Black_wbqq0m.png";
let zest =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581682102/projectLogo/zest_logo_C_ctj916.png";
let primodial =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Primordial-House_ime8mz.png";
let primodialAnnex =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Annex_itzbgt.png";
let bluePlanet =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/Blue_Planet_Logo_C-8_ty3sit.png";
let chaitya =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/Chaitya777_Logo_C-8_k5artg.png";
let p1925 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/The_1925_Logo_C-8_my4qtm.png";
let allure =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Sambhav-Allure_udusae.png";
let vins = "https://res.cloudinary.com/dymmjqche/image/upload/v1624017171/projectLogo/vins_jdm7br.jpg";
let twinHouse = "https://res.cloudinary.com/dymmjqche/image/upload/v1624017171/projectLogo/twin-house_qj0o6w.jpg";
let stairwayHouse = 'https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Stairway-House_sgrqci.png';
let walkeshwar = 'https://res.cloudinary.com/dymmjqche/image/upload/v1626525110/projectLogo/Walkeshwar_d3d0wl.png';
let current = 1;
let mobHome =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566811495/mobHome_dzhhf6.png";
let arrow =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580895013/arrow_rvbyx4.svg";
let south =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633527694/projectLogo/homePageMap/South_j7snc9.jpg";
let south_mob =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633528251/projectLogo/homePageMap/Mob-South_t70xqs.jpg";
let central =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633528053/projectLogo/homePageMap/Central_fnkrtd.jpg";
let central_mob =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633529084/projectLogo/homePageMap/Mob-Central_xqvlfr.jpg"
let north =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633527881/projectLogo/homePageMap/North_uffl1c.jpg";
let north_mob =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633529084/projectLogo/homePageMap/Mob-North_fj7b2p.jpg"
let other =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626524714/projectLogo/homePageMap/Beyond-Mumbai.jpg";
class Home extends Component {
  constructor() {
    super()
    this.state = {
      value: "",
      isVisible: false,
      isActive: "South Mumbai",
      activeBG: this.checkDesk() ? south : south_mob,
      projectZone: [
        {
          img: twinHouse,
          zone: "Beyond Mumbai",
          link: "the-twin-house"
        },
        {
          img: chaitya,
          zone: "Central Mumbai",
          link: "chaitya777"
        },
        {
          img: bluePlanet,
          zone: "Central Mumbai",
          link: "blue-planet"
        },
      ],
      northMumbai: [
        {
          img: vins,
          zone: "North Mumbai",
          link: "vins"
        },
        {
          img: zest,
          zone: "North Mumbai",
          link: "zest"
        },
        {
          img: tiara,
          zone: "North Mumbai",
          link: "tiara"
        },
        {
          img: eden,
          zone: "North Mumbai",
          link: "eden"
        },
        {
          img: allure,
          zone: "South Mumbai",
          link: "#"
        },
      ],
      southMumbai: [
        {
          img: primodial,
          zone: "South Mumbai",
          link: "primodial"
        },
        {
          img: walkeshwar,
          zone: "South Mumbai",
          link: "walkeshwar"
        },
        {
          img: primodialAnnex,
          zone: "South Mumbai",
          link: "primodial-annex"
        },
        {
          img: p1925,
          zone: "South Mumbai",
          link: "1925"
        },
        {
          img: stairwayHouse,
          zone: "South Mumbai",
          link: "the-stairway-house"
        }
      ]
    };
  }

  checkDesk = () => {
    return window.innerWidth > 768
  }
  componentDidMount() {
    $("html").css("overflow-y", "auto");
    $.scrollify.disable();
  }

  handleChange = event => {
    this.setState({
      value: event.target.value.substr(0, 20),
      isVisible: true
    });
  };
  next = () => {
    if (current === 4) {
      current = 1;
    } else {
      current += 1;
    }
    if (current == 1) {
      return this.setState({ isActive: "South Mumbai", activeBG: this.checkDesk() ? south : south_mob });
    } else if (current == 2) {
      return this.setState({ isActive: "North Mumbai", activeBG: this.checkDesk() ? north : north_mob });
    } else if (current == 3) {
      return this.setState({ isActive: "Central Mumbai", activeBG: this.checkDesk() ? central : central_mob });
    } else if (current == 4) {
      return this.setState({ isActive: "Beyond Mumbai", activeBG: other });
    }
  };
  prev = () => {
    if (current === 1) {
      current = 4;
    } else {
      current -= 1;
    }
    if (current == 1) {
      console.log(window.innerWidth >= 480);
      return this.setState({ isActive: "South Mumbai", activeBG: this.checkDesk() ? south : south_mob });
    } else if (current == 2) {
      return this.setState({ isActive: "North Mumbai", activeBG: this.checkDesk() ? north : north_mob });
    } else if (current == 3) {
      return this.setState({ isActive: "Central Mumbai", activeBG: this.checkDesk() ? central : central_mob });
    } else if (current == 4) {
      return this.setState({ isActive: "Beyond Mumbai", activeBG: other });
    }
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    };
    const imageReplace = () => {
      if ($(window).width() < 800) {
        return mobHome;
      } else {
        return home;
      }
    };
    const renderProjects = () => {
      if (this.state.isActive === "South Mumbai") {
        return <Slider {...settings}>
          {this.state.southMumbai.map((item, index) => {
            return (
              <NavLink to={"/portfolio/" + item.link}>
                <img className="ani-show" src={item.img} alt="" />
              </NavLink>
            );
          })}
        </Slider>
      } else if (this.state.isActive === "North Mumbai") {
        return <Slider {...settings}>
          {this.state.northMumbai.map((item, index) => {
            return (
              <NavLink to={"/portfolio/" + item.link}>
                <img className="ani-show" src={item.img} alt="" />
              </NavLink>
            );
          })}
        </Slider>
      }
    };
    const renderProjectsWithoutSlider = () => {
      return this.state.projectZone.map((item, index) => {
        if (item.zone === this.state.isActive)
          return (
            <NavLink to={"/portfolio/" + item.link}>
              <img className="ani-show" src={item.img} alt="" />
            </NavLink>
          );
      });
    }
    return (
      <div className="home-container">
        <div className="homeLogo">
          <img src={whiteLogo} alt="" />
        </div>
        <Background />
        <div className="panelContainer">
          <div className="left">
            <img className="ani-show" src={this.state.activeBG} alt="" />
            {/* <p className="phrase">
              Homes of tomorrow, today
            </p> */}
          </div>
          <div className="right">
            <div className="selector">
              <img
                className="prevArrow"
                src={arrow}
                alt="Previous"
                onClick={() => this.prev()}
              />
              <div className="ani-show">
                <p>{this.state.isActive}</p>
              </div>
              <img
                className="nextArrow"
                src={arrow}
                alt="Next"
                onClick={() => this.next()}
              />
            </div>
            {this.state.isActive === "South Mumbai" || this.state.isActive === "North Mumbai" ? renderProjects() : <div className="projects">{renderProjectsWithoutSlider()}</div>}
            {/* <div className="search-container">
            <Slider {...settings}>
              {this.state.southMumbai.map((item, index) => {
                return (
                  <NavLink to={"/portfolio/" + item.link}>
                    <img className="ani-show" src={item.img} alt="" />
                  </NavLink>
                );
              })}
            </Slider>
              <input
                type="text"
                placeholder="Search by Area, Postcode"
                id="search-text-input"
                onChange={this.handleChange}
                value={this.state.value}
              />
              <div
                className="search"
                // onClick={() => renderSearch(this.state.value)}
                onClick={() => this.props.searchClick(this.state.value)}
              >
                <img src={search} alt="" id="button-holder" />
              </div>
            </div> */}
            <div className="sm-icon">
              <a href="https://www.facebook.com/Sambhavgroup9" target="_blank">
                <img src={fb} alt="" />
              </a>
              <a href="https://www.instagram.com/sambhavgroup" target="_blank">
                <img src={insta} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/sambhav-group/"
                target="_blank"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fab fa-pinterest-p"></i>
              </a>
            </div>
          </div>
          {this.state.value ? (
            <div className="searchContainer">
              {/* <div className="noResult">No Result</div>; */}

              {/* {renderSearch()} */}
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    activeSubCategory: state.activeSubCategory,
    isVisible: state.isVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchClick: name => dispatch({ type: "searchClick", value: name }),
    projectClick: name => dispatch({ type: "projectClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
// let filterProject = this.props.projects.filter(project => {
//   return project.area.indexOf(this.state.value.toLowerCase()) !== -1;
// });

// const renderSearch = () => {
//   let temp = 0;
//   let data = filterProject.map((item, index) => {
//     // if (this.state.value.toLowerCase() == item.area) {
//     temp = 1;
//     return (
//       <NavLink
//         key={Number(index + 1)}
//         to={"/portfolio/" + item.title}
//         onClick={() => this.props.projectClick(item.title)}
//       >
//         <li
//           className="result-list"
//         // onClick={() => this.props.activeSubCategories(item.title)}
//         >
//           {item.title}
//         </li>
//       </NavLink>
//     );
//     // }
//   });
//   if (temp == 1) {
//     return data;
//   } else {
//     return <div className="noResult">No Result</div>;
//   }
// };
