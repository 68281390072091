import React, { Component } from "react";
import linkcss from "./link.css";
import { nextPage } from "../animation/Animation";
class Links extends Component {
  state = {
    aboutus: [
      {
        title: "Overview",
        section: "01"
      },
      {
        title: "Presevation & Construction",
        section: "02"
      },
      {
        title: "Architect & Design",
        section: "03"
      },
      {
        title: "Sustainability",
        section: "04"
      },
      {
        title: "Core Team",
        section: "05"
      }
    ]
  };
  render() {
    const printLines = sectionNumber => {
      //   alert(sectionNumber);
      return this.state.aboutus.map((data, index) => {
        if (data.title == this.props.activeLink) {
          // alert(data.title);
          return (
            <div className="active-line" key={index}>
              <div className="line" />
              <div className="section-number ani-show">
                {this.props.activeLink}
              </div>
            </div>
          );
        } else {
          return (
            <div
              className={"inactive-line " + sectionNumber}
              onClick={() => nextPage(data.section)}
              key={index}
            >
              <div className="section-number ani-show">{data.title}</div>
              <div className="line" />
            </div>
          );
        }
      });
    };

    return (
      <div className={"link-container " + this.props.className}>
        {printLines()}
      </div>
    );
  }
}

export default Links;
