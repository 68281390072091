import React, { Component } from "react";
import "./page1.css";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import whiteLogo from "../images/whiteLogo.png";
import { fadeIn, bounceIn } from 'react-animations'

let logo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580898799/logo_b552uw.png";
let zestlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572085250/Projects/Zest/zest_vaclpd.png";
class Page1 extends Component {
  state = {};

  componentDidMount() {
    // this.props.projectClick(this.props.activeCategory);
    // alert(this.props.activeCategory);
  }


  render() {
    return (
      <div className={"page1Container " + this.props.className}>
        <NavLink to="/">
          <img className="logo animated bounceIn animation" src={whiteLogo} />
        </NavLink>
        {
          this.props.projects.map((item, index) => {
            if (this.props.activeProject === item.title) {
              return (
                <div className="infoContainer fadeIn">
                  <div className="logoImage">
                    <img src={item.logo} alt="" />
                  </div>
                  <p>{item.tagline}</p>
                </div>
              );
            }
          })
        }
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    // activeProject: state.activeProject,
    activeMap: state.activeMap,
    activeThumb: state.activeThumb
  };
};

const mapDispatchToProps = dispatch => {
  return {
    thumbClick: name => dispatch({ type: "thumbClick", value: name }),
    projectClick: name => dispatch({ type: "projectClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page1);
