import React, { Component } from "react";
import footercss from "./footer.css";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import linkd from "../images/linkd.png";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  state = {
    imageSRC: [fb, insta, linkd]
  };
  render() {
    return (
      <div className="footer-container">
        <div className="footer-email">
          {" "}
          <a href="mailto:info@sambhavgroup.co.in" target="_top">
            info@sambhavgroup.com
          </a>
        </div>
        <div className="footer-group">&copy; Sambhav Group</div>.
        <div className="footer-sm">
          <a href="https://www.facebook.com/Sambhavgroup9" target="_blank">
            <img className="sm-logo" src={this.state.imageSRC[0]} />
          </a>
          <a href="https://www.instagram.com/sambhavgroup" target="_blank">
            <img className="sm-logo" src={this.state.imageSRC[1]} />
          </a>
          <a
            href="https://www.linkedin.com/company/sambhav-group/"
            target="_blank"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="#">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i class="fab fa-pinterest-p"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
