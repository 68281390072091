import React, { Component } from "react";
import logo from "../images/logo.png";
import menu from "../images/menu.png";
import downArrow from "../images/downArrow.png";
import bgcss from "./background.css";
import commoncss from "../common.css";
import Menu from "../menu/Menu";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import { nextPage, next } from "../animation/Animation";

class Background extends Component {
  state = {};

  menuToggle() {
    // $("html").css("overflow-y", "hidden");
    // $("body").css("overflow-y", "hidden");
    // $(".menu-section").css("display", "block");
    $(".menu-section").css("opacity", "1");
    $(".menu-section").removeClass("animated fadeOutRight animation");
    $(".menu-section").addClass("animated slideInRight animation");
    setTimeout(function () {
      $(".menu-section").css("display", "block");
    }, 100);
  }
  componentDidMount() {
    $("html").css("overflow-y", "scroll");
    // $("body").css("overflow-y", "auto");
  }
  render() {
    return (
      <div className="BG-Container">
        <div className="menu-section">
          <Menu />
        </div>
        <div className="header-container">
          <NavLink to="/">
            <img className="logo animated bounceIn animation" src={logo} />
          </NavLink>
          <div className="menu-container" onClick={() => this.menuToggle()}>
            <img className="menu" src={menu} />
          </div>
        </div>

        {(() => {
          if (this.props.bgLine)
            return (
              <div className="line-container">
                <div className="line1" />
                <div className="line1 line2" />
                <div className="line1 line3" />
                <div className="line1 line4" />
                <div className="line5" />
                <div className="line5 line6" />
              </div>
            );
          else return <React.Fragment />;
        })()}

        {(() => {
          if (this.props.bgLine)
            return (
              <div className="mline-container">
                <div className="mline1" />
                <div className="mline1 mline2" />
                <div className="mline3" />
                <div className="mline3 mline4" />
                {/* <div className="mline5" /> */}
                {/* <div className="mline5 mline6" /> */}
              </div>
            );
          else return <React.Fragment />;
        })()}

        {(() => {
          if (this.props.title == null) return <React.Fragment />;
          else
            return (
              <div className="title-container">
                <div
                  className={
                    "text animated fadeIn animation " + this.props.className
                  }
                >
                  <div className="textLine1 animated slideInLeft animation" />
                  {this.props.title}
                  <div className="textLine2 animated slideInRight animation" />
                </div>
              </div>
            );
        })()}
        {/* <div className="title-line1" />
          <div className="title-line2" /> */}
        {/* <div className="footer">
          <Footer />
        </div> */}

        {(() => {
          if (this.props.downArrow)
            return (
              <div className="downArrow-container" onClick={() => next()}>
                <img src={downArrow} alt="" />
              </div>
            );
          else return <React.Fragment />;
        })()}
      </div>
    );
  }
}

export default Background;
