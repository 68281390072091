import React, { Component } from "react";
import $ from "jquery";
import presscss from "./news.css";
import news1 from "../images/news1.png";
class News extends Component {
  state = {};

  componentDidMount() {
    $("html").css("overflow-y", "auto");
  }
  render() {
    return (
      <div className="newsContainer animated fadeInDown animation">
        <a href={this.props.links} target="_blank">
          <img src={this.props.imgSrc} alt="" />

          <div className="newsText">Click to read</div>
        </a>
      </div>
    );
  }
}

export default News;
