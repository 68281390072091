import React, { Component } from "react";
import pcss from "./p.css";
class P extends Component {
  state = {};
  render() {
    return (
      <div
        className={
          "para-container animated fadeInDown animation " + this.props.className
        }
      >
        {this.props.p}
      </div>
    );
  }
}

export default P;
