import React, { Component } from "react";
import h1css from "./h1.css";
import commoncss from "../common.css";

class H1 extends Component {
  state = {};
  render() {
    return (
      <div
        className={
          "h1-container animated slideInDown animation " + this.props.className
        }
      >
        {this.props.h1}
      </div>
    );
  }
}

export default H1;
