//import next from "../jsx/Animation";
import $ from "jquery";
import h1 from "../images/h1.png";
import h2 from "../images/h2.png";
import h3 from "../images/h3.png";
import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import s4 from "../images/p4.png";
import s1 from "../images/p1.png";
import s2 from "../images/p2.png";
import s3 from "../images/p3.png";
// let zest1 =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/1_mojjeh.jpg";
// let zest2 =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/2_of4c6c.jpg";
// let zest3 =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/3_uwnkc1.jpg";
// let zest4 =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/4_y8sftd.jpg";
// let zest5 =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/3_uwnkc1.jpg";
let tph1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584338557/Projects/TPH/tph1_xflztr.jpg";
let tph2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584338571/Projects/TPH/tph2_p6nj4x.jpg";
let tph3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584338560/Projects/TPH/1_zoeeib.jpg";
let tph4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584338542/Projects/TPH/2_mts_1_uy4rrz.jpg";
let tph5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584338537/Projects/TPH/2_mts_bvdpqv.jpg";
let p1925 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580993123/Projects/1925/TN1_j8hybn.jpg";
let bplanet =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566027483/Projects/Blue%20Planet/1_d3dzog.jpg";
let chaitya =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566369821/Projects/Chaitya/Chaitya_zlpfxb.jpg";
let tiara1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566904191/Projects/Tiara/Tiara-day.jpg";
let tiara2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566904190/Projects/Tiara/Tiara_night.jpg";
let automatedParking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566286309/HIghlights/automatedParking_fggvvr.png";
let gymnasium =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566286426/HIghlights/weightlifting_gmkoqp.png";
let podiumParking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566286535/HIghlights/parking_zrk7xd.png";
let pool =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157101/Projects/Zest/Amenities/Pool_yk623h.svg";
let architecture =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566288323/HIghlights/TropicalArch_nvvr2w.png";
let carParking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566287141/HIghlights/carParking_v8vabc.png";
let theater =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566287457/HIghlights/home-cinema_jl2mdt.png";
let garden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566287448/HIghlights/garden_hjzq6j.png";
let terraceGarden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566367166/HIghlights/terraceGarden_b2ek2h.png";
let frenchWindow =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566367553/HIghlights/frenchWindow_xrox6y.png";
let skyGarden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566366254/HIghlights/watering_ajtegi.png";
let skyRoof =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566368283/HIghlights/table_whc6se.png";
let centileverParking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566366752/HIghlights/Cantilever_parking_a9439p.png";
let chargingPoint =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566366927/HIghlights/charging_gz5y1r.png";

let p19251 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241049/Terrace_Still_003-min-min_i0p3fk.jpg";
let p19252 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241049/View_2-min-min_jxmkow.jpg";
let p19253 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241048/Living_Dinning_View_1-min-min_kbahsf.jpg";
let p19254 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241047/Terrace_Still_002-min-min_n78rvm.jpg";
let p19255 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241047/Lobby_View_2-min-min_cvkvzx.jpg";
let p19256 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241047/Terrace_Still_001-min-min_ykxdhj.jpg";
let p19257 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241047/Lobby_View_3-min-min_zlw8wd.jpg";
let p19258 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1600241047/Lobby_View_1-min-min_huryng.jpg";
let p19259 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626275916/Projects/1925/1_gt5hq5.jpg";
let p192510 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626275916/Projects/1925/2_ibiu4n.jpg";
let p192511 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626275916/Projects/1925/3_nd6ffp.jpg";
let p192512 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626275916/Projects/1925/4_mtffh9.jpg";
let p192513 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773271/Projects/1925/b1_2_rlrpsb.jpg";
let p192514 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773272/Projects/1925/b2_2_ghg3dj.jpg";
let p192515 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773272/Projects/1925/b3_2_qeb60y.jpg";
let p192516 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773272/Projects/1925/b4_2_uuuqi5.jpg";
let p192517 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773272/Projects/1925/b5_2_fnnj5c.jpg";
let p192518 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b6_2_cclmfu.jpg";
let p192519 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b7_2_xvxetl.jpg";
let p192520 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b8_2_ke7x1y.jpg";
let p192521 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b9_2_yrlths.jpg";
let p192522 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b10_2_fhmbxi.jpg";
let p192523 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773273/Projects/1925/b11_2_asghij.jpg";
let p192524 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773274/Projects/1925/b12_2_xt0ahf.jpg";
let p192525 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773274/Projects/1925/b13_2_fsg2rn.jpg";
let p192526 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626773271/Projects/1925/b14_2_cthgny.jpg";
// The twin house
let twin1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856552/Projects/Twin%20house%20lonavala/1_fdujbm.jpg";
let twin2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856551/Projects/Twin%20house%20lonavala/2_h2rwu8.jpg";
let twin3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856553/Projects/Twin%20house%20lonavala/3_hlgzzz.jpg";
let twin4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856551/Projects/Twin%20house%20lonavala/4_ffmdgl.jpg";
let twin5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856553/Projects/Twin%20house%20lonavala/5_plqdob.jpg";
let twin6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856549/Projects/Twin%20house%20lonavala/6_xfla47.jpg";

// ZEST STARTS
let Zest1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/1_mojjeh.jpg";
let Zest2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/2_of4c6c.jpg";
let Zest3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/4_y8sftd.jpg";
let Zest4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1565073326/Projects/Zest/5_sazjam.jpg";
let Zest5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/1_2_zaumzq.jpg";
let Zest6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/2_2_jwfl9j.jpg";
let Zest7 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/3_2_ld5nxq.jpg";
let Zest8 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/4_2_jbgidj.jpg";
let Zest9 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/5_2_m1qxvi.jpg";
let Zest10 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537555/Projects/Zest/6_2_hmcuuv.jpg";
let Zest11 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537556/Projects/Zest/7_2_l7826l.jpg";
let Zest12 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537556/Projects/Zest/8_2_kmdphf.jpg";
let Zest13 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537556/Projects/Zest/9_2_y63utb.jpg";
let Zest14 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537557/Projects/Zest/10_2_tlzr0t.jpg";
let Zest15 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537557/Projects/Zest/11_2_h5rlvk.jpg";
let Zest16 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537556/Projects/Zest/12_2_qmhnwj.jpg";
let Zest17 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626537554/Projects/Zest/13_2_eleqzk.jpg";

let eden1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336174/Projects/Eden/01_FINAL_oniotp.jpg";
let eden2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336177/Projects/Eden/final_02_yqm1un.jpg";
let eden3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336170/Projects/Eden/FINAL_03_e0gtyo.jpg";
let eden4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336167/Projects/Eden/final_04_dpomuw.jpg";
let eden5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336168/Projects/Eden/final_05_tan2xu.jpg";
// TPX ANNEX
let tpha1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167287/Projects/TPHAnnex/1_g9ap6b.jpg";
let tpha2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167287/Projects/TPHAnnex/2_bzyqns.jpg";
let tpha3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167270/Projects/TPHAnnex/3_dvosbr.jpg";
let tpha4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167281/Projects/TPHAnnex/4_gybaoc.jpg";
let tpha5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167259/Projects/TPHAnnex/5_jdsxhl.jpg";
let tpha6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626167300/Projects/TPHAnnex/6_ifjj10.jpg";
// ZEST map

let ZEST_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984953/Projects/Zest/map/Project_Map_-_Zest_Hospitals_kygkg7.jpg";
let ZEST_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984945/Projects/Zest/map/Project_Map_-_Zest_Restaurants_and_Bars_ueycv0.jpg";
let ZEST_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984960/Projects/Zest/map/Project_Map_-_Zest_Petrol_Pumps_zl1vf4.jpg";
let ZEST_Public_Transport =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572193224/Projects/Zest/map/Public_Transport_vehsqa.jpg";
let ZEST_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984949/Projects/Zest/map/Project_Map_-_Zest_Fitness_ovtidj.jpg";
let ZEST_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984948/Projects/Zest/map/Project_Map_-_Zest_Educational_Institutions_jmnrms.jpg";
let ZEST_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580984953/Projects/Zest/map/Project_Map_-_Zest_Entertainment_lagvwt.jpg";

//ZEST ENDS

//map thumb
let Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Hospitals_-_O_fonch7.png";
let Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681011/Projects/Zest/map/Restaurants_and_Bars_-_O_ygnkgj.png";
let petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Petrol_Pumps_-_O_pwj611.png";
let Public_Transport =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Public_Transport_-_O_gri948.png";
let Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Fitness_-_O_sivnut.png";
let Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Educational_Institutions_-_O_bsppfy.png";
let Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581681010/Projects/Zest/map/Entertainment_-_O_y6jjxh.png";
let Supermarket =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626535839/Projects/Zest/map/supermarket_lo0ugb.png";

//Tiara map

let Tiara_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986588/Projects/Tiara/map/Project_Map_-_Tiara_Hospitals_-01_1_zw15ms.jpg";
let Tiara_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986593/Projects/Tiara/map/Project_Map_-_Tiara_Restaurants_and_Bars_-01_1_pbtjw6.jpg";
let Tiara_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986583/Projects/Tiara/map/Project_Map_-_Tiara_Petrol_Pumps_-01_1_pwpjub.jpg";
let Tiara_Public_Transport =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1572193224/Projects/Zest/map/Public_Transport_vehsqa.jpg";
let Tiara_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986589/Projects/Tiara/map/Project_Map_-_Tiara_Fitness_-01_1_x0urha.jpg";
let Tiara_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986588/Projects/Tiara/map/Project_Map_-_Tiara_Eductional_Institutions_-01_1_rc8kds.jpg";
let Tiara_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580986589/Projects/Tiara/map/Project_Map_-_Tiara_Entertainment_-01_1_yqmorb.jpg";

//Eden map

let Eden_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989159/Projects/Eden/Project_Map_-_Eden_Hospitals_-01_zfowjc.jpg";
let Eden_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989172/Projects/Eden/Project_Map_-_Eden_Restaurants_and_Bars_-01_xbkawg.jpg";
let Eden_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989159/Projects/Eden/Project_Map_-_Eden_Petrol_Pumps_-01_wm3day.jpg";
let Eden_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989160/Projects/Eden/Project_Map_-_Eden_Fitness_-01_hbxxza.jpg";
let Eden_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989166/Projects/Eden/Project_Map_-_Eden_Educational_Institutions_-01_dcukgk.jpg";
let Eden_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580989184/Projects/Eden/Project_Map_-_Eden_Entertainament_-01_ryxa9b.jpg";

//1925 map

let p1925_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336528/Projects/1925/map/Project_Map_-_The_1925_-_Hospitals-01_xddbhz.jpg";
let p1925_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336528/Projects/1925/map/Project_Map_-_The_1925_-_Restaurants_and_Bars-01_safgfx.jpg";
let p192_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336526/Projects/1925/map/Project_Map_-_The_1925_-_Petrol_Pumps-01_vplqyx.jpg";
let p1925_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336532/Projects/1925/map/Project_Map_-_The_1925_-_Fitness-01_n08vvo.jpg";
let p1925_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336530/Projects/1925/map/Project_Map_-_The_1925_-_Schools-01_ql7vlf.jpg";
let p1925_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1584336528/Projects/1925/map/Project_Map_-_The_1925_-_Entertainment-01_eg1nxs.jpg";

//bluePlanet map

let bluePlanet_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996101/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Hospitals__1_rn2lmz.jpg";
let bluePlanet_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996102/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Restaurants_and_Bars__1_jja7fs.jpg";
let bluePlanet_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996104/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Petrol_Pumps__1_fypljw.jpg";
let bluePlanet_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996103/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Fitness__1_tn5xo0.jpg";
let bluePlanet_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996103/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Educational_Institutions__1_wzbdf6.jpg";
let bluePlanet_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1580996109/Projects/Blue%20Planet/map/Project_Map_-_Blue_Planet_Entertainment__1_eono6e.jpg";

//Chaitya777 map

let Chaitya777_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622689/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Hospitals-01_lj9ke2.png";
let Chaitya777_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622683/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Restaurants_and_Bars-01_qz37ia.png";
let Chaitya777_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622684/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Petrol_Pumps-01_dlkzck.png";
let Chaitya777_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622683/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Fitness-01_qlxfbo.png";
let Chaitya777_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622682/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Educational_Institutions-01_adv0mm.png";
let Chaitya777_Entertainment =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1589622683/Projects/Chaitya/map/Project_Map_-_Chaitya_777_-_Entertainment-01_wd9ufm.png";

// Vins
let vins1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856403/Projects/Vins/1_2_onzzt6.jpg";
let vins2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856408/Projects/Vins/2_2_vcrfpc.jpg";
let vins3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856406/Projects/Vins/3_2_tzzopx.jpg";
let vins4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856407/Projects/Vins/4_2_oog9pi.jpg";
let vins5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534697/Projects/Vins/1_xu9s81.jpg";
let vins6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534698/Projects/Vins/2_h3wfxn.jpg";
let vins7 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534697/Projects/Vins/3_tzazjf.jpg";
let vins8 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534697/Projects/Vins/4_mylxjf.jpg";
let vins9 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534697/Projects/Vins/5_dtfyze.jpg";
let vins10 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534698/Projects/Vins/6_mpraos.jpg";
let vins11 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534697/Projects/Vins/7_bhhqit.jpg";

// Vins Map
let vins_resturant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534890/Projects/Vins/maps/Resturants_2_izwhhm.jpg";
let vins_supermarket =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534890/Projects/Vins/maps/Supermarkets_2_rzzsei.jpg";
let vins_petrol =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534891/Projects/Vins/maps/Petrol-Pumps_2_f8kdpm.jpg";
let vins_hospital =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534890/Projects/Vins/maps/Hospital_2_u4tpgm.jpg";
let vins_education =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534890/Projects/Vins/maps/Education_2_mu0iuy.jpg";
// The Stairway House
let stairway1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856209/Projects/Stairway%20house%20renders/1_gs7dti.jpg";
let stairway2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856209/Projects/Stairway%20house%20renders/2_x0audp.jpg";
let stairway3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856208/Projects/Stairway%20house%20renders/3_h4nmxq.jpg";
let stairway4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856209/Projects/Stairway%20house%20renders/4_gdqcu5.jpg";
let stairway5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856211/Projects/Stairway%20house%20renders/5_h4r2hw.jpg";
let stairway6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856210/Projects/Stairway%20house%20renders/6_irg0cs.jpg";
let stairway7 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623856206/Projects/Stairway%20house%20renders/7_aturyw.jpg";
// The Stairway House Map
let stairway_education =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534471/Projects/Stairway%20house%20renders/maps/Education_2_kvvckr.jpg";
let stairway_petrol =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534471/Projects/Stairway%20house%20renders/maps/Petrol-Pumps_2_ffu1jl.jpg";
let stairway_resturant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534471/Projects/Stairway%20house%20renders/maps/Resturants_2_bio7jl.jpg";
let stairway_supermarket =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534471/Projects/Stairway%20house%20renders/maps/Supermarkets_2_gpbtew.jpg";
let stairway_hospital =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626534471/Projects/Stairway%20house%20renders/maps/Hospitals_2_ockitw.jpg";
//TPH map
let TPH_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157330/Projects/TPH/map/Project_Map_-_TPH_Hospitals_-01_rpoitv.jpg";
let TPH_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157332/Projects/TPH/map/Project_Map_-_TPH_Restaurants_and_Bars_-01_nxj4k9.jpg";
let TPH_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157330/Projects/TPH/map/Project_Map_-_TPH_Petrol_Pumps_-01_nmebqn.jpg";
let TPH_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157334/Projects/TPH/map/Project_Map_-_TPH_Fitness_-01_nh0rkv.jpg";
let TPH_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157333/Projects/TPH/map/Project_Map_-_TPH_Educational_Institutions_-01-01_puerzd.jpg";

//TPH map
let TPHa_Hospitals =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157697/Projects/TPHAnnex/map/Project_Map_-_TPH_-_Annexe_Hospitals_-01_i49ydf.jpg";
let TPHa_Restaurant =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157696/Projects/TPHAnnex/map/Project_Map_-_TPH_-_Annexe_Restaurants_and_Bars_-01_kiulo9.jpg";
let TPHa_petrolPump =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157696/Projects/TPHAnnex/map/Project_Map_-_TPH_-_Annexe_Petrol_Pumps_-01_k9yeij.jpg";
let TPHa_Fitness =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157909/Projects/TPHAnnex/map/Project_Map_-_TPH_-_Annexe_Fitness_-01_dysnhn.jpg";
let TPHa_Educational_Institutions =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581157685/Projects/TPHAnnex/map/Project_Map_-_TPH_-_Annexe_Educational_Institutions_z9dpb4.jpg";

// Amenities Starts
let balcony =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Balcony_h6yl30.png";
let wifi =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/HighSpeed_Wifi_v3j6oc.png";
let Safety_Railings =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678234/Projects/Zest/Amenities/Safety_Railings_zdkris.png";
let Pocket_Terrace =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Pocket_Terrace_ijzqpv.png";
let NaturallyLitLobby =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Naturally-Lit_Lobby_ebr9m0.png";
let Rooftop_Garden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Rooftop_Garden_kqwust.png";
let Interntional_Sanitaryware =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/International_Sanitaryware_rgp7dr.png";
let Housekeeping =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/Housekeeping_g0wt9n.png";
let Covered_Parking =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Covered_Parking_bxjw5l.png";
let Centralised_Cable_TV =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Centralised_Dish_TV_wclhhr.png";
let High_speed_elevator =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/high-Speed_Elevators_pqnn2j.png";
let French_Windows =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/French_Windows_s9va0t.png";
let CCTV_Cameras =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/CCTV_Cameras_t05ihi.png";
let Car_Lift =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Car_Lift_ftzndy.png";
let car_charging =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Car-charging_station_egrfh0.png";
let centralised_dish =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678231/Projects/Zest/Amenities/Centralised_Dish_TV_wclhhr.png";
let coWorkingArea =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/Co-working_Area_fuowkl.png";
let game_Room =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/Game_room_zngvne.png";
let gym =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678232/Projects/Zest/Amenities/Gym_vzlm5l.png";
let juice_bar =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Juice_Bar_iksxxl.png";
let Naturally_lit_lobby =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Naturally-Lit_Lobby_ebr9m0.png";
let Outdoor_dining =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Outdoor_Dining_Area_tccvh9.png";
let pet_friendly =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Pet-Friendly_qyzez7.png";
let rainwater_harvesting =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678234/Projects/Zest/Amenities/Rainwater_Harvesting_vefktf.png";
let recycling_bin =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Recycling_Bins_qi7pkr.png";
let rooftop_cafe =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678233/Projects/Zest/Amenities/Rooftop_Cafe_svquty.png";
let solar_panel =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678234/Projects/Zest/Amenities/Solar_Panels_bbvxon.png";
let yogaRoom =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581678234/Projects/Zest/Amenities/Yoga_Room_isxjkx.png";
// Amenities Ends
// Allure
let allure1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/1.jpg";
let allure2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/2.jpg";
let allure3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/3.jpg";
let allure4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/4.jpg";
let allure5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533895/Projects/Allure/5.jpg";
let allure6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533895/Projects/Allure/6.jpg";
let allure7 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533895/Projects/Allure/7.jpg";
let allure8 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/8.jpg";
let allure9 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/9.jpg";
let allure10 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626533894/Projects/Allure/10.jpg";

// Walkeshwar
let wal1 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/1_2_cmagcu.jpg";
let wal2 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/2_2_ggmozl.jpg";
let wal3 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/3_2_sir7m2.jpg";
let wal4 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/4_2_hcgc1y.jpg";
let wal5 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/5_2_sjy9yq.jpg";
let wal6 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536532/Projects/Walkeshwar/6_2_fa2vkk.jpg";
let wal7 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626536531/Projects/Walkeshwar/7_2_iqxy2q.jpg";

//colored logo
let zestlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581682102/projectLogo/zest_logo_C_ctj916.png";
let eden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/Eden_Logo_C-8_hsvcmq.png";
let tiaralogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581757115/projectLogo/Tiara_Logo-8_eyq0py.png";
let logo1925 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/The_1925_Logo_C-8_my4qtm.png";
let bluePlanetlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671789/projectLogo/Blue_Planet_Logo_C-8_ty3sit.png";
let chaityaLogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1582090073/projectLogo/chaitya-777-Colour-01_nstpjv.png";
let primodiallogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Primordial-House_ime8mz.png";
// let primodialAnnexlogo =
//   "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Annex_itzbgt.png";
let primodialAnnexlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1633530402/projectLogo/Annex_Logo_lgen6w.png";
let twinHouse =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1624017171/projectLogo/twin-house_qj0o6w.jpg";
let vins =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1624017171/projectLogo/vins_jdm7br.jpg";
let stairwayHouse =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525111/projectLogo/Stairway-House_sgrqci.png";
let Walkeshwar =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525110/projectLogo/Walkeshwar_d3d0wl.png";
let allure =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626525110/projectLogo/Walkeshwar_d3d0wl.png";
//logo
let wZestlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/Zest_Logo_W-8_ileloo.png";
let wtiaralogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/Tiara_Logo_W-8_gshlz3.png";
let wlogo1925 =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/The_1925_Logo_W-8_gz54lw.png";
let wEden =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/Eden_Logo_W-8_bn1nwu.png";
let wbluePlanetlogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/Blue_Planet_Logo_W-8_mczvrc.png";
let wchaityaLogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1581671233/projectLogo/whiteLogo/Chaitya777_Logo_W-8_ap5xxx.png";
let wprimodiallogo =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1624445793/projectLogo/whiteLogo/TPH_bu2h9v.png";
let wtwinHouse =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623857444/projectLogo/whiteLogo/Twin_House_Lonavala_01_qwp3h1.png";
let wtpha =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1623857445/projectLogo/whiteLogo/TPH_ANEX_LOGO_bx6uyb.png";
let wvins =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626531892/projectLogo/whiteLogo/Vins_yw370s.png";
let wstairwayHouse =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626531892/projectLogo/whiteLogo/Stairway-House-White_n8p1nv.png";
let wWalkeshwar =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1626531892/projectLogo/whiteLogo/Walkeshwar-white_aguv68.png";
// const projects = [
//   {
//     // category: "COMMERCIAL-OFFICE",
//     subcategory: "Completed",
//     title: "ZEST",
//     titleImg: zest2,
//     hover: "ZEST",
//     desc1:
//       "Located at the heart of Bandra, Zest was more of a passion project than just an apartment building. Zest’s design emphasizes on style, luxury, comfort while providing adequate homes for living life to the fullest. Hence the name.Each home at Zest was planned and designed so as to optimize the flow of natural light and allow cross ventilation, thus encouraging a healthy and breathable space. The exterior façade of Zest was designed to impersonate the culture of the vibrant locality it stood in.",
//     desc2Header: "The Neighbourhood",
//     desc2:
//       "Bandra is laced with vibrancy and style and a varied mix of cultures. To explore Bandra is to be welcomed with peaceful bylanes but also to get engulfed in the liveliness of the locality. From tiny cafés to Portuguese churches, Bandra is a bustling town catering to the young and the old alike.",
//     desc3:
//       "Talking about Waterfield road, where Zest stands so proudly, it’s flanked by the best of restaurants and places to shop. From classy restaurants like Bastian, Gustoso, and Hakkasan to pubs like House of Tipsy, 145 Bandra and TAP resto, Waterfield road is the ideal place for either a quiet dinner to painting the town red. A book connoisseur can find book stores such as Crosswords to cafés like Birdsong café  which host reading events at regular intervals. One can have access to one of the most premium gyms in the city or utilize the running stretch at Carters to keep up the regime. A booming night life and a buzzing day life is what one can expect at Bandra.",
//     highlight: [carParking, skyGarden, architecture],
//     imgSrc1: zest1,
//     imgSrc2: zest2,
//     imgSrc3: zest3,
//     activeImg: zest1,
//     thumbs: [zest1, zest2, zest3, zest4, zest5],
//     area: "bandra"
//   },
//   {
//     // category: "COMMERCIAL-OFFICE",
//     subcategory: "Completed",
//     title: "The Primordial House",
//     titleImg: tph1,
//     hover: "The Primordial House",
//     desc1:
//       "The Primordial House is a French architecture inspired structure to depict the way the French lived.  A mix of French luxury and their way of living can be seen in the designs of the edifice. The structure is adorned with large French windows and huge balconies making it aesthetically appealing to the ones living in it and to the ones passing by. Overlooking the Arabian sea, these homes are a charming proof of luxury and class.",
//     desc2:
//       "Kemps corner is regarded as one of the prime locations of South Bombay. The neighborhood boasts of a platoon of high rises standing tall amidst a few heritage sites namely August Kranti marg and the Hanging garden. Cross a few lanes and one is welcomed by the grandeur of the Arabian Sea and Mumbai’s priced Queen’s necklace.",
//     desc3:
//       "The Primordial House is flanked by Nana chowk on one side and heritage on the other. The locality holds a cluster of restaurants to choose from to eat to designer boutiques to satiate the need for shopping. With prominent names like Payal Singhal, Shyamal & Bhumika alike as designers, there are restaurants such as Gallops, China Garden, Gustoso to choose from. A few minutes away from the Mahalakshmi race course, one can have access to elite derby shows and cultural festivals to connect to people with similar affinity.",
//     highlight: [podiumParking, pool, gymnasium, theater],
//     imgSrc1: tph1,
//     imgSrc2: tph2,
//     imgSrc3: tph3,
//     activeImg: tph3,
//     thumbs: [tph1, tph2, tph3, tph4],
//     area: "kemps corner"
//   },
//   {
//     subcategory: "Ongoing",
//     title: "1925",
//     titleImg: p1925,
//     hover: "1925",
//     desc1:
//       "This project has been undertaken to modify a seemingly simple edifice into a voguish contemporary structure. Our aim for this project is to provide affordable luxury for the aspirational audience in the vicinity of Girgaon.  The design is inspired by the old Victorian style houses that once stood in the surroundings and with that we hope to retain the history of Tara Niwas built in 1925.",
//     desc2:
//       "Girgaon is home to eclectic architecture and is a melting pot of different cultures. Apart from arich history it also withholds Rajera landmarks like the Royal Opera House and other Portuguese-style wooden buildings. The legendary Harkrishan Das hospital (now Reliance Hospital) and Prarthna Samaj circle the area where 1925 stands. Proximity to the Girgaon Chowpatty and Marine drive while being in the heart of the area makes it easier for the ones living in 1925 to distance themselves from the hustle and bustle. The local dining scene and night life offers local vegetarian restaurants and many Pan Asian eateries.",
//     desc3:
//       "Apart from resturants, Girgoan houses multiple lifestyle brands to cater to individual styles and preferences plus various departmental stores for one’s daily needs. Tier 1 schools and colleges with access to bookstores to cater to the thriving young crowd is something that can also be seen in the whole of Girgaon.",
//     highlight: [carParking, gymnasium],
//     imgSrc1: "",
//     imgSrc2: "",
//     imgSrc3: "",
//     activeImg: "",
//     thumbs: "",
//     area: "girgaon"
//   },
//   {
//     subcategory: "Ongoing",
//     title: "The Primordial House – Annex",
//     titleImg: p3,
//     hover: "The Primordial House – Annex",
//     desc1:
//       "Kemps corner is regarded as one of the prime locations of South Bombay. The neighborhood boasts of a platoon of high rises standing tall amidst a few heritage sites namely August Kranti marg and the Hanging garden. Cross a few lanes and one is welcomed by the grandeur of the Arabian Sea and Mumbai’s priced Queen’s necklace.",
//     desc2:
//       "The Primordial House is flanked by Nana chowk on one side and heritage on the other. The locality holds a cluster of restaurants to choose from to eat to designer boutiques to satiate the need for shopping. With prominent names like Payal Singhal, Shyamal & Bhumika alike as designers, there are restaurants such as Gallops, China Garden, Gustoso to choose from.",
//     desc3:
//       "A few minutes away from the Mahalakshmi race course, one can have access to elite derby shows and cultural festivals to connect to people with similar affinity.",
//     highlight: [h2, h1, h3],
//     imgSrc1: p3,
//     imgSrc2: p1,
//     imgSrc3: p2,
//     activeImg: s2,
//     thumbs: [s2, s3, s4, s4, p3],
//     area: "kemps corner"
//   },
//   {
//     subcategory: "Completed",
//     title: "Blue Planet",
//     titleImg: bplanet,
//     hover: "Blue Planet",
//     desc1:
//       "Blue Planet is constructed in the beautiful vicinity of Mazgaon in South Mumbai. Surrounded by the Arabian sea on one side and harbor view on the other side, Blue Planet is a reflection of contemporary Mumbai.  An unobstructed view upto 3kms radius up in the sky, is the quintessential break you need from the daily hassles of life.  ",
//     desc2:
//       "Mazgaon still retains a few heritage sites reminiscent of the old Portuguese Mumbai. With the dockyard giving Mazgaon its harbor view, there are a few historic monuments as well too look at. The Joseph Bapista garden and the Kuan Kung temple are just a few of the many places to visit in Mazgaon.",
//     desc3:
//       "With a range of multi-cuisine restaurants available, it’s never difficult to find a place to eat in Mazgaon.  With proximity to the eastern harbor, eclectic food and art scenes, Mazgaon has slowly become a melting pot of different cultures.",
//     highlight: [gymnasium, podiumParking],
//     imgSrc1: p3,
//     imgSrc2: p1,
//     imgSrc3: p2,
//     activeImg: s2,
//     thumbs: [s2, s3, s4, s4, p3],
//     area: "mazgaon"
//   },
//   {
//     subcategory: "Completed",
//     title: "Tiara",
//     titleImg: tiara1,
//     hover: "Tiara",
//     desc1:
//       "Nestled in the suburban part of Mumbai, Tiara was constructed with the idea of having a crown jewel in the humble neighborhood of Goregaon. The elevation of Tiara mirrors the surrounding infrastructure of the locality and blends in with it. ",
//     desc2:
//       "Goregaon is a hustling bustling suburb which is lively and vibrant. Tiara is flanked by new emerging malls, theatres and an array of restaurants to pick and choose from for a pleasant evening. For a nice evening out shopping, people can choose to go to Oberoi mall on the Western Express Highway or the Hub mall. ",
//     desc3:
//       "Tiara has a proximity to places like the Goregaon film city in Aarey where one can go and experience the limelight first hand. Goregaon west is also a mixed bucket of some interesting street food and a mix of pubs to enjoy a carefree evening.",
//     highlight: [terraceGarden, frenchWindow],
//     imgSrc1: p3,
//     imgSrc2: p1,
//     imgSrc3: p2,
//     activeImg: s2,
//     thumbs: [s2, s3, s4, s4, p3],
//     area: "goregaon"
//   },
//   {
//     subcategory: "Upcoming",
//     title: "Chaitya 777",
//     titleImg: chaitya,
//     hover: "Chaitya 777",
//     desc1: "",
//     desc2: "",
//     desc3: "",
//     desc4: "This is an upcoming project. Stay tuned.",
//     highlight: "",
//     imgSrc1: "",
//     imgSrc2: "",
//     imgSrc3: "",
//     activeImg: "",
//     thumbs: "",
//     area: "lalbaug"
//   },
//   {
//     subcategory: "Upcoming",
//     title: "Sai Chhaya",
//     titleImg: p3,
//     hover: "Sai Chhaya",
//     desc1: "",
//     desc2: "",
//     desc3: "",
//     desc4: "This is an upcoming project. Stay tuned.",
//     highlight: "",
//     imgSrc1: "",
//     imgSrc2: "",
//     imgSrc3: "",
//     activeImg: "",
//     thumbs: "",
//     area: "kandivali"
//   },
//   {
//     subcategory: "Upcoming",
//     title: "Express, Forjit Street",
//     titleImg: p3,
//     hover: "Express, Forjit Street",
//     desc1: "",
//     desc2: "",
//     desc3: "",
//     desc4: "This is an upcoming project. Stay tuned.",
//     highlight: "",
//     imgSrc1: "",
//     imgSrc2: "",
//     imgSrc3: "",
//     activeImg: "",
//     thumbs: "",
//     area: "tardeo"
//   },
//   {
//     subcategory: "Ongoing",
//     title: "Jay Bhawani",
//     titleImg: p3,
//     hover: "Jay Bhawani",
//     desc1:
//       "Nestled in the quiet and serene corner of Vile Parle East, this structure isvdesigned to blend incwith the surrounding and the aesthetic appeal of the neighborhood.The edifice is made to suffice the basic of comfort of all inhabitants and provide with necessary amenities.",
//     desc2:
//       "Jay Bhawani is situated at the quiet corner of Vile Parle east yet accessible to the main roads leading to the railway station and the western express highway. Vile parle is now slowly becoming a hub for many eateries and a vibrant neighbourhood to live in.",
//     desc3:
//       "situated almost in the middle of the city strip, Vile Parle is an accessible suburb to settle for ease of travel for the office goers. Apart from easy access to the railway station and the highway, Vile Parle is also at a close proximity to Mumbai's International Airport.",
//     highlight: [skyRoof, gymnasium, centileverParking, chargingPoint],
//     imgSrc1: "",
//     imgSrc2: "",
//     imgSrc3: "",
//     activeImg: "",
//     thumbs: "",
//     area: "vile parle"
//   }
// ];

const projects = [
  {
    title: "walkeshwar",
    tagline: "",
    desc: "Walkeshwar is located close to the sea and offers a view of the water body. The crème de la crème of society. Malabar Hill is located on the southern-most tip of Mumbai, which gives it a three-side view of the Arabian Sea. The area is also famous for Hanging Gardens, and a hillside park. It offers picturesque views of the Bandra-Worli Sea Link, the Arabian Sea and the Race Course. There are a number of commercial establishments in the area, including shopping malls, restaurants, night clubs, etc., which makes it one of the popular hangout regions for office-goers working in the island city.",
    neighbourDesc:
      "An on-site gym or fitness centre, a pool, and even a playground or community centre all help residents feel more at-home (and comfortable) in the society. Whether there’s a shared outdoor grilling space for cookouts, a multi-purpose room that can be reserved for parties and meetings, or a library and media room that can be used for studying or working from home, amenities make living in a building better. The location gives you the western shore of Colaba and is now dotted with residential and commercial skyscrapers, where property prices have touched at exorbitant levels. With a number of five-star hotels in its proximity. Keeping you close to Colaba, Worli and Prabhadevi.",
    titleImg: wal1,
    logo: Walkeshwar,
    hover: [
      {
        logo: wWalkeshwar,
        projectName: "Walkeshwar",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [wal1, wal2, wal3, wal4, wal5, wal6, wal7],
    amenities: [
      Safety_Railings,
      Interntional_Sanitaryware,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
    ],
    map: [
      {
        name: "Education",
        img: stairway_education,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Supermarket",
        img: stairway_supermarket,
        mapThumb: Supermarket,
      },
      {
        name: "Hospital",
        img: stairway_hospital,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: stairway_petrol,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: stairway_resturant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "the-stairway-house",
    tagline: "",
    desc: "The project is located in Mumbai, India and has been designed keeping in mind our potential clients and future residents. Although the structures around the project are dilapidated, we hope that in the coming times as redevelopment of even those structures begins, the area will look and become like any other with great societies and proper apartment buildings.",
    neighbourDesc:
      "We’ve given a green terrace to the building with plants and trees making it a pleasant place to sit and while away an evening. We’ve also given metal louvers in the exterior to create a design to soothe give a great impression of the structure. The material of the exterior walls and staircases have been designed and made with red concrete with the louvers made from metal.",
    titleImg: stairway1,
    logo: stairwayHouse,
    hover: [
      {
        logo: stairwayHouse,
        projectName: "The Stairway House",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [
      stairway1,
      stairway2,
      stairway3,
      stairway4,
      stairway5,
      stairway6,
      stairway7,
    ],
    amenities: [
      Safety_Railings,
      Interntional_Sanitaryware,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
    ],
    map: [
      {
        name: "Education",
        img: stairway_education,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Supermarket",
        img: stairway_supermarket,
        mapThumb: Supermarket,
      },
      {
        name: "Hospital",
        img: stairway_hospital,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: stairway_petrol,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: stairway_resturant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "vins",
    tagline: "",
    desc: "Bandra West- Mumbai's first lifestyle suburb has a mix of old and new, where Portuguese architecture and old churches sit alongside large, trendy residences. It's a perfect residential place for locals as well as expats. Moreover, it has several restaurants and shops in its vicinity and is only an hour from south Mumbai, via the Bandra-Worli Sea Link. The ‘queen of the suburbs’ as Bandra is often called!",
    neighbourDesc:
      "We bring to you VINS. Located in Bandra, The western neighbourhood within Mumbai, a boutique residential structure, stands tall in the urban landscape of the city gives you Spacious Studio Apartments that are bold and offer plenty of storage options, with charm eluding from every angle. Fulfil all dreams, from modern style or a home with classic building features.",
    titleImg: vins1,
    logo: wvins,
    hover: [
      {
        logo: wvins,
        projectName: "Vins",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [
      vins1,
      vins2,
      vins3,
      vins4,
      vins5,
      vins6,
      vins7,
      vins8,
      vins9,
      vins10,
      vins11,
    ],
    amenities: [
      Safety_Railings,
      Interntional_Sanitaryware,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
    ],
    map: [
      {
        name: "Education",
        img: vins_education,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Supermarket",
        img: vins_supermarket,
        mapThumb: Supermarket,
      },
      {
        name: "Hospital",
        img: vins_hospital,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: vins_petrol,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: vins_resturant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "the-twin-house",
    tagline: "",
    desc: "Located in Lonavala, The Twin House Villa offers unobstructed views of paddy fields & lush hills. This Villa comes built-in with world-class modern amenities, landscaped gardens, massive sit-outs, balconies and a Swimming Pool, a Jacuzzi, a Theatre, a Gym, a Cafeteria and last but not least, a game room that makes for a quintessential holiday home for you and your lovely family. Looking out Lonavala's most beautiful sights, this home is sure to leave you feeling inspired and delighted for years to come. The ideal holiday escape.",
    neighbourDesc:
      "A palace-like charm in a modern 21st century home. The Twin House Villa is where you get a real sense of eternal beauty; a perfect blend of traditional and modern elements.",
    titleImg: twin1,
    logo: wtwinHouse,
    hover: [
      {
        logo: wtwinHouse,
        projectName: "The Twin House",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [twin1, twin2, twin3, twin4, twin5, twin6],
    amenities: [
      Safety_Railings,
      Interntional_Sanitaryware,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
    ],
    map: [
      {
        name: "Education",
        img: Tiara_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: Tiara_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: Tiara_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: Tiara_Hospitals,
        mapThumb: Hospitals,
      },

      {
        name: "Petrol_Pump",
        img: Tiara_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: Tiara_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "zest",
    tagline: "",
    desc: "Located in Bandra, western neighbourhood within Mumbai, Z Residence is a 7-storey mixed use boutique residential structure. The building with its natural texture blends into the existing green fabric. Standing tall in the urban landscape of the city, the building is designed with 3-key elements, Volume, Passive Shading devices and Materiality. The exterior façade of the building has been framed by beveled slab projections, modelled to reduce heat gains through passive cooling.",
    neighbourDesc:
      "To achieve the flow of fresh air throughout the apartments, the habitable spaces have been placed along the length of the apartment block. Green pockets are interspersed across the façade and act as solar buffers to the habitable spaces. In a city faced with a dearth of open spaces, these pockets serve as habitable pockets of respite. Covering the flanks are undulating wooden panels that aid in reducing solar gains across the surface. The juxtaposition of timber and finished concrete add a tropical vibe to the aesthetic of the building sitting well within its urban context.",
    titleImg: Zest2,
    logo: zestlogo,
    hover: [
      {
        logo: zestlogo,
        projectName: "Zest",
        progress: "Completed",
      },
    ],
    slideImg: [
      Zest1,
      Zest2,
      Zest3,
      Zest4,
      Zest5,
      Zest6,
      Zest7,
      Zest8,
      Zest9,
      Zest10,
      Zest11,
      Zest12,
      Zest13,
      Zest14,
      Zest15,
      Zest16,
      Zest17,
    ],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Pocket_Terrace,
      NaturallyLitLobby,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      Centralised_Cable_TV,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
    ],
    map: [
      {
        name: "Education",
        img: ZEST_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: ZEST_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: ZEST_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: ZEST_Hospitals,
        mapThumb: Hospitals,
      },
      // {
      //   name: "Public_Transport",
      //   img: ZEST_Public_Transport,
      //   mapThumb: Public_Transport
      // },
      {
        name: "Petrol_Pump",
        img: ZEST_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: ZEST_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "primodial-annex",
    tagline: "",
    desc: "The Primordial House - Annex, an extension of The Primordial House, is located in the bustling hub of Kemp’s Corner. It is indeed an elegant and alluring have for those who want that extra luxury quotient.",
    neighbourDesc:
      "This location comes with a perfect combination of contemporary architecture and features to provide a comfortable living, it enjoys excellent connectivity and is largely self-sufficient with high-grade educational institutions, healthcare facilities, banks, offices as well as hotels and fine dining restaurants available right around the corner. Revolving around the French architectural concept, the aesthetically pleasing and ravishing decor of the 2BHK and 3BHK apartments are truly desirable in every way. We, at Sambhav Group, have invested in giving a homely habitat to our clients. ",
    titleImg: tpha6,
    logo: primodialAnnexlogo,
    hover: [
      {
        logo: wtpha,
        projectName: "The Primordial House ANNEX",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [tpha1, tpha2, tpha3, tpha4, tpha5, tpha6],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
      car_charging,
      centralised_dish,
      game_Room,
      gym,
      Naturally_lit_lobby,
      pet_friendly,
      recycling_bin,
      pool,
      solar_panel,
    ],
    map: [
      {
        name: "Education",
        img: TPH_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Fitness",
        img: TPH_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: TPH_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: TPH_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: TPH_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "primodial",
    tagline: "",
    desc: "The Primordial is located in the bustling hub of Kemp’s Corner. It is indeed an elegant and alluring haven for those who want that extra luxury quotient.Flanked by high rises, The Primordial House is a symbol of French living and stands apart and with huge balconies and large Georgian bar windows to view the cityscape in all its glory.Overlooking the Arabian Sea, these homes are the epitome of a five-star lifestyle.",
    neighbourDesc:
      "The Primodial House’s design pays homage French colonial structures, and façades made with COR-TEN steel giving the building a vintage and complete look. Revolving around the French architectural concept, the aesthetically pleasing and ravishing decor of the 2BHK and 3BHK apartments are truly desirable in every way.",
    titleImg: tph1,
    logo: primodiallogo,
    hover: [
      {
        logo: wprimodiallogo,
        projectName: "The Primordial House",
        progress: "Completed",
      },
    ],
    slideImg: [tph1, tph2, tph3, tph4, tph5],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
      car_charging,
      centralised_dish,
      game_Room,
      gym,
      Naturally_lit_lobby,
      pet_friendly,
      recycling_bin,
      pool,
      solar_panel,
    ],
    map: [
      {
        name: "Education",
        img: TPH_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Fitness",
        img: TPH_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: TPH_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: TPH_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: TPH_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "eden",
    tagline: "",
    desc: "Standing amidst the now bustling hub of Parle, the new prince of the suburbs, this boutique residence seeks to bring nature into the lives of its inhabitants.As our domain seeps into the suburbs, we think that it prudent to create a landmark structure looking into the future. In the age of global warming, and concretization, the building will be made with sustainable materials, and will be filled with an abundance of tropical greenery evoking nature with a serene vertical landscape.",
    neighbourDesc:
      "Nestled in the quiet and serene corner of Vile Parle East, this structure is designed to blend in with the surrounding and the aesthetic appeal of the neighbourhood.Vile Parle is now slowly becoming a hub for many eateries and a vibrant neighbourhood to live in. ",
    titleImg: eden1,
    logo: eden,
    hover: [
      {
        logo: wEden,
        projectName: "Eden",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [eden1, eden2, eden3, eden4, eden5],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      Centralised_Cable_TV,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
      car_charging,
      centralised_dish,
      game_Room,
      gym,
      Outdoor_dining,
      pet_friendly,
      recycling_bin,
      rooftop_cafe,
      solar_panel,
    ],
    map: [
      {
        name: "Education",
        img: Eden_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: Eden_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: Eden_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: Eden_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: Eden_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: Eden_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "1925",
    tagline: "",
    desc: "As the century old stone walls of the structure standing prior to The 1925, crumble down, in its place rises a worthy contender to the art deco style of buildings that were prevalent in the era that has now gone by. Taking inspiration from the load bearing stone masonry walls and the arches of the Tara Mansion, this contemporary design is an homage to the Art Deco aesthetics that were once at the forefront of design.",
    neighbourDesc:
      "The 1925’s shape is inspired by the old Victorian style houses that once stood in its surroundings, and with that we hope to retain the history of Tara Niwas built in 1925. Here, Sambhav merges the old with the new, with an intent to fit this story within an urban context. Elements of the era gone by can be found within the details of The 1925 in the context of modernism, in the gentle curve of the overhangs, in the design of the railings, and in the stone cladding of the old structure that lines the lobby.",
    titleImg: p192512,
    logo: logo1925,
    hover: [
      {
        logo: wlogo1925,
        projectName: "The 1925",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [
      p192513,
      p192514,
      p192515,
      p192516,
      p192517,
      p192518,
      p192519,
      p192520,
      p192521,
      p192522,
      p192523,
      p192524,
      p192525,
      p192526,
      p19259,
      p192510,
      p192511,
      p192512,
      p19251,
      p19252,
      p19253,
      p19254,
      p19255,
      p19256,
      p19257,
      p19258,
    ],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      yogaRoom,
      Covered_Parking,
      // Centralised_Cable_TV,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
      car_charging,
      centralised_dish,
      game_Room,
      gym,
      Outdoor_dining,
      pet_friendly,
      recycling_bin,
      rooftop_cafe,
      solar_panel,
    ],
    map: [
      {
        name: "Education",
        img: p1925_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: p1925_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: p1925_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: p1925_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: p192_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: p1925_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "blue-planet",
    tagline: "",
    desc: "Blue Planet is constructed in the beautiful vicinity of Mazgaon, in South Mumbai. Surrounded by the Arabian sea on one side, and harbour view on the other side, Blue Planet is a reflection of contemporary Mumbai.  An unobstructed view up to three kilometers radius up in the sky, is the quintessential break you need from the daily hassles of life.Equipped with modern day amenities, Blue Planet lets you live a healthy life with its indoor gymnasium, and a safe space for your four wheelers with its multi-level podium parking.",
    neighbourDesc:
      "With a range of multi-cuisine restaurants available nearby, it’s never difficult to find a place to eat in Mazgaon.  With proximity to the eastern harbour, eclectic food and art scenes, Mazgaon has slowly become a melting pot of different cultures.",
    titleImg: bplanet,
    logo: bluePlanetlogo,
    hover: [
      {
        logo: wbluePlanetlogo,
        projectName: "Blue Planet",
        progress: "Completed",
      },
    ],
    slideImg: [bplanet],
    amenities: [
      balcony,
      wifi,
      Interntional_Sanitaryware,
      rainwater_harvesting,
      Safety_Railings,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      centralised_dish,
      gym,
      recycling_bin,
    ],
    map: [
      {
        name: "Education",
        img: bluePlanet_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: bluePlanet_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: bluePlanet_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: bluePlanet_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: bluePlanet_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: bluePlanet_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "chaitya777",
    tagline: "",
    desc: "Chaitya 777 is one of the premium projects in South Mumbai. It is a 37 floor high-rise palatial residential tower based in Lalbaug enjoying the grand views of the Mahalakshmi Race course and the Arabian Sea.",
    neighbourDesc:
      "This iconic tower, located centrally, is aimed to provide luxurious and elegant houses with world class amenities.",
    titleImg: chaitya,
    logo: chaityaLogo,
    hover: [
      {
        logo: wchaityaLogo,
        projectName: "Chaitya 777",
        progress: "Ongoing Project",
      },
    ],
    slideImg: [chaitya],
    amenities: [
      balcony,
      wifi,
      Safety_Railings,
      Rooftop_Garden,
      Interntional_Sanitaryware,
      Housekeeping,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
      Car_Lift,
      car_charging,
      centralised_dish,
      game_Room,
      gym,
      Naturally_lit_lobby,
      pet_friendly,
      recycling_bin,
      Pocket_Terrace,
      solar_panel,
    ],
    map: [
      {
        name: "Education",
        img: Chaitya777_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: Chaitya777_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: Chaitya777_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: Chaitya777_Hospitals,
        mapThumb: Hospitals,
      },
      {
        name: "Petrol_Pump",
        img: Chaitya777_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: Chaitya777_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  {
    title: "tiara",
    tagline: "",
    desc: "Nestled in the suburban part of Mumbai, Tiara was constructed with the idea of having a crown jewel in the neighborhood of Goregaon. The elevation of Tiara mirrors the surrounding infrastructure of the locality and blends in with it.The contemporary apartment building, makes it one of its kind with the spectacular view and modern amenities. ",
    neighbourDesc:
      "Goregaon is a hustling bustling suburb which is lively and vibrant. Tiara is flanked by new emerging malls, theatres and an array of restaurants to pick and choose from for a pleasant evening. For a nice evening out shopping, people can choose to go to Oberoi mall on the Western Express Highway, or even, the Hub mall. Tiara has a proximity to places like the Goregaon film city in Aarey colony where one can go and experience the limelight first hand. Goregaon west is also a mixed bucket of some interesting street food and pubs to enjoy a carefree evening.",
    titleImg: tiara1,
    logo: tiaralogo,
    hover: [
      {
        logo: wtiaralogo,
        projectName: "Tiara",
        progress: "Completed",
      },
    ],
    slideImg: [tiara1, tiara2],
    amenities: [
      Safety_Railings,
      Interntional_Sanitaryware,
      Covered_Parking,
      High_speed_elevator,
      French_Windows,
      CCTV_Cameras,
    ],
    map: [
      {
        name: "Education",
        img: Tiara_Educational_Institutions,
        mapThumb: Educational_Institutions,
      },
      {
        name: "Entertainment",
        img: Tiara_Entertainment,
        mapThumb: Entertainment,
      },
      {
        name: "Fitness",
        img: Tiara_Fitness,
        mapThumb: Fitness,
      },
      {
        name: "Hospital",
        img: Tiara_Hospitals,
        mapThumb: Hospitals,
      },

      {
        name: "Petrol_Pump",
        img: Tiara_petrolPump,
        mapThumb: petrolPump,
      },
      {
        name: "Restaurant",
        img: Tiara_Restaurant,
        mapThumb: Restaurant,
      },
    ],
  },
  // {
  //   title: "primodial-annex",
  //   tagline: "",
  //   desc:
  //     "The Primordial House - Annex, an extension of The Primordial House, is located in the bustling hub of Kemp’s Corner. It is indeed an elegant and alluring haven for those who want that extra luxury quotient.Overlooking the Arabian Sea, these homes are the epitome of a five-star lifestyle.",
  //   neighbourDesc:
  //     "Revolving around the French architectural concept, the aesthetically pleasing and ravishing decor of the 2BHK and 3BHK apartments are truly desirable in every way.",
  //   titleImg: tpha3,
  //   logo: primodialAnnexlogo,
  //   hover: [{
  //     logo: primodialAnnexlogo,
  //     projectName: "The Primordial House - Annex",
  //     progress: "Completed"

  //   }],
  //   slideImg: [tpha1, tpha2, tpha3, tpha4, tpha5],
  //   amenities: [
  //     balcony,
  //     wifi,
  //     Safety_Railings,
  //     Rooftop_Garden,
  //     Interntional_Sanitaryware,
  //     Housekeeping,
  //     Covered_Parking,
  //     High_speed_elevator,
  //     French_Windows,
  //     CCTV_Cameras,
  //     Car_Lift,
  //     car_charging,
  //     centralised_dish,
  //     game_Room,
  //     gym,
  //     Naturally_lit_lobby,
  //     pet_friendly,
  //     recycling_bin,
  //     pool,
  //     solar_panel,

  //   ],
  //   map: [
  //     {
  //       name: "Education",
  //       img: TPHa_Educational_Institutions,
  //       mapThumb: Educational_Institutions
  //     },
  //     {
  //       name: "Fitness",
  //       img: TPHa_Fitness,
  //       mapThumb: Fitness
  //     },
  //     {
  //       name: "Hospital",
  //       img: TPHa_Hospitals,
  //       mapThumb: Hospitals
  //     },
  //     {
  //       name: "Petrol_Pump",
  //       img: TPHa_petrolPump,
  //       mapThumb: petrolPump
  //     },
  //     {
  //       name: "Restaurant",
  //       img: TPHa_Restaurant,
  //       mapThumb: Restaurant
  //     }
  //   ]
  // },
];

const subcategory = [
  {
    title: "All",
  },
  {
    title: "Ongoing",
  },
  {
    title: "Completed",
  },
  {
    title: "Upcoming",
  },
];
const team = [
  {
    title: "Mr. Prakash Jain",
    desc: [
      "With more than four decades of successful experience in the space of jewelery, entertainment, and investment, Mr. Prakash Jain founded Sambhav Real Estate in 1988, to deliver high quality living to the people of Mumbai.",
      "His belief in personal relationships over commercial gains and quality over quantity has percolated itself into the DNA of the business practices at Sambhav.",
      "His biggest strength lies in the understanding opportunities and then deal with both, micro and macro dynamics of those opportunities, and delivering projects that endure, despite the ever-changing fads of consumers and the ever-changing legal framework.",
      "With over four decades of experience, Mr. Prakash Jain has built a place for himself in competitive businesses like jewelery, entertainment, investments, and of course, real estate. His mantra is simple: “Give the customer an experience that stays with them”",
    ],
  },
  {
    title: "Vinay Jain",
    desc: [
      "Mr. Vinay Jain comes from a family with a widespread portfolio, spanning various industries, that are spread across the city. Real estate, being one of them. Vinay brings with him experienced in the realms of strategizing, financing and marketing.",
      "He is extremely sensitive towards his customers’ requirements and uses a strictly transparent style of workmanship. His passion, commitment to ethics, and belief in the growth and benefits of every stakeholder gives the customer a special edge.",
      "His strident belief in creativity and innovation has directed him to take up projects which have a rich history and he has thus, taken them up as a challenge to modify them into a new generation’s living. His expertise in understanding the location and surroundings of a project, the demographics, and zeroing down on the diverse needs of his potential buyers, is the reason why Sambhav Group has achieved accolades in their field.",
    ],
  },
  {
    title: "Hitesh Jain",
    desc: [
      "Mr. Hitesh Jain comes with 20 years of experience in real estate. He handles the legal, accounts, finance and liaison functions of the business of Sambhav.",
      "He has motivated the team to keep doing better through his work. He is also involved in the fundraising activities for the group for which he has a good network with Private Equity companies, Bankers and investment bankers.",
      "He takes a personal interest in the satisfaction of each and every customer. He has also created a platform where every customer can give his/her opinions and is constantly updated about the progress of the project.",
    ],
  },
  {
    title: "Sahil Jain",
    desc: [
      "Mr. Sahil Jain is a post-graduate in Emerging Technologies and Design from the Architectural Association at London. Having initially worked for Sanjay Puri Architects, he got on board with Sambhav Group in 2012 intending to pursue exploration in design through parametricism and digital fabrication. He derives inspiration from his travels across the world, especially through the lens of his camera. He is constantly seeking out new experiences to enrich his outlook towards the world of design and architecture.",
      "He brings in international experience to build a space of great design, high quality and longevity, by using the latest technologies and techniques of construction and fabrication, so that the consumer gets more value for his/her purchase.",
    ],
  },
];
const initialState = {
  subcategory: subcategory,
  projects: projects,
  activeProject: "Zest",
  activeSubCategory: "All",
  titleArray: [],
  idd: 0,
  loading: true,
  isVisible: false,
  activeMap: "Education",
  activeThumb: "Education",
  team: team,
  activeTeam: "Mr. Prakash Jain",
};
const reducer = (state = initialState, action) => {
  const newState = { ...state };

  //   const clientArray = () => {
  //     newState.clientName = [];
  //     projects.map((item, index) => {
  //       //alert(newState.category + "==" + item.category);
  //       if (newState.category == item.category) {
  //         newState.clientName.push(item.client_name);
  //       }
  //     });
  //     newState.activeClient =
  //       newState.clientName[newState.idd % newState.clientName.length];
  //   };
  const titleArray = () => {
    newState.titleArray = [];
    //alert("array activeCategory" + newState.activeCategory);
    projects.map((item, index) => {
      newState.titleArray.push(item.title);
    });
  };
  const changeActiveProject = () => {
    newState.titleArray.map((item, index) => {
      if (item == newState.activeProject) {
        newState.idd = index;
      }
    });
  };
  // const renderSearch = name => {
  //   {
  //     subcategory.map((item, index) => {
  //       if (name == item.area) {
  //         return (
  //           <li
  //             id="link"
  //             className="sublinks"
  //             onClick={() => this.props.activeSubCategories(item.title)}
  //           >
  //             {item.title}
  //           </li>
  //         );
  //       }
  //     });
  //   }
  // };
  switch (action.type) {
    case "projectClick": {
      newState.activeProject = action.value;
      break;
    }
    case "activeSubCategories": {
      newState.activeSubCategory = action.value;
      if ($(window).width() < 800) {
        $(".drop-contt").toggle();
        $(".js-content").css("display", "none");
        if (action.value == "All") {
          $("#option1").css("display", "inline-block");
        }
        if (action.value == "Ongoing") {
          $("#option2").css("display", "inline-block");
        }
        if (action.value == "Completed") {
          $("#option3").css("display", "inline-block");
        }
        if (action.value == "Upcoming") {
          $("#option4").css("display", "inline-block");
        }
      }

      // alert(newState.activeSubCategory);
      break;
    }
    case "next": {
      titleArray();
      // alert(newState.activeProject);
      newState.activeProject =
        newState.titleArray[(newState.idd + 1) % newState.titleArray.length];
      changeActiveProject();
      break;
    }
    case "prev": {
      titleArray();
      newState.activeProject =
        newState.titleArray[
          (newState.titleArray.length + newState.idd - 1) %
            newState.titleArray.length
        ];
      changeActiveProject();
      break;
    }
    case "set_loading": {
      newState.loading = false;
      break;
    }
    case "thumbClick": {
      newState.activeThumb = action.value;
      // alert(action.value);
      break;
    }
    case "teamClick": {
      newState.activeTeam = action.value;
      break;
    }
  }
  return newState;
};

export default reducer;
