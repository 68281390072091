import React, { Component } from "react";
import loadingcss from "./loading.css";
import $ from "jquery";

let loadingGif = "https://res.cloudinary.com/dymmjqche/image/upload/v1570772587/Sambhav_uak7v7.gif";
class Loading extends Component {
  state = {};
  componentDidMount() {


  }
  render() {
    return (
      <div className="loadContainer">
        <img src={loadingGif} alt="" />
      </div>
    );
  }
}

export default Loading;
