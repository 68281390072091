import React, { Component } from "react";
import Background from "../Background/Background";
import AboutUs1 from "../aboutus1/AboutUs1";
import AboutUs2 from "../aboutus2/AboutUs2";
import AboutUs3 from "../aboutus3/AboutUs3";
import AboutUs4 from "../aboutus4/AboutUs4";
import AboutUs5 from "../aboutus5/AboutUs5";
import AboutUscss from "./about.css";
import $ from "jquery";
import { entryanimation } from "../animation/Animation";
import Footer from "../footer/Footer";
import { scrollifyy } from "../animation/Animation";
import { scrollify } from "jquery-scrollify";
import Links from "../links/Links";

class AboutUs extends Component {
  state = {};
  componentDidMount() {
    $.scrollify.disable();
    $("html").css("overflow-y", "auto");

    entryanimation("aboutus", "01");
    scrollifyy("aboutus");
  }
  render() {
    return (
      <div className="aboutUs-Container">
        <Background title="" bgLine={false} downArrow={true} title="Overview" />
        <AboutUs1 className="customCss1" />
        <AboutUs2 className="customCss2" />
        <AboutUs3 className="customCss3" />
        <AboutUs4 className="customCss4" />
        <AboutUs5 className="customCss5" />

        <div className="aboutus-footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default AboutUs;
