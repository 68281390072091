import React, { Component } from "react";
import slidercss from "./slider.css";
import p4 from "../images/p4.png";
import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import { connect } from "react-redux";
import $ from "jquery";
let activeImg = "";
class Slider extends Component {
  state = {
    thumbIndex: 0
  };
  obj(thumbs) {
    $(".active-img").attr("src", thumbs);
  }
  render() {
    // const renderImg = () => {
    //   return (
    //     <div className="thumb-container" onClick={this.props.onClick}>
    //       <img src={this.props.slider} alt="" />
    //     </div>
    //   );
    // };
    const renderSlider = () => {
      return this.props.projects.map((item, index) => {
        if (item.title == this.props.activeProject) {
          if (item.thumbs) {
            return item.thumbs.map((slider, index) => {
              return (
                <img src={slider} alt="" onClick={() => this.obj(slider)} />
              );
            });
          }
        }
      });
    };

    const renderActiveImg = () => {
      return this.props.projects.map((item, index) => {
        if (item.title == this.props.activeProject) {
          // activeImg = item.thumbs[this.state.thumbIndex];
          return <img className="active-img" src={item.activeImg} alt="" />;
        }
      });
    };
    return (
      <div className="slider-container">
        <div className="active-img">
          {/* <img className="active-img" src={activeImg} alt="" /> */}
          {renderActiveImg()}
          <div className="thumb-container" onClick={this.props.onClick}>
            {renderSlider()}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    activeProject: state.activeProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectClick: name => dispatch({ type: "projectClick", value: name })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Slider);
