import React, { Component } from "react";
import AboutUs1css from "./aboutus2.css";
import Links from "../links/Links";
import prev from "../images/prev.png";
import { nextPage } from "../animation/Animation";
import $ from "jquery";
import Background from "../Background/Background";
import P from "../p/P";
class AboutUs2 extends Component {
  state = {};
  render() {
    return (
      <div className={"aboutus02-container " + this.props.className}>
        <div className="backSection" onClick={() => nextPage("01")}>
          <img src={prev} alt="" className="backBtn" />
          Back
        </div>
        <Links activeLink={"Presevation & Construction"} />

        {/* <Background
          title=""
          bgLine={false}
          downArrow={true}
          title="Presevation &amp; Construction"
        /> */}
        <div className="panelContainer">
          <div className="section-1" />
          <div className="section-2">
            <P
              className="p-adjust"
              p="Sambhav is well-versed in the significance of historical structures in Mumbai. They believe in maintaining the essence of that history when replaced with new developments. Sambhav finds purpose in transforming the heritage buildings into luxurious and modern living spaces."
            />
            <br />
            <P
              className="p-adjust"
              p="We understand that the structures once built, continue to hold their beauty through different generations and eras.  With our new projects, we carry forward a sense of the history of the development’s previous respective edifice and aim to conserve the essence that it brought along."
            />
            <br />
            {$(window).width() <= 800 ? (
              ""
            ) : (
              <>
                <P
                  className="p-adjust"
                  p="We focus on preserving what once stood tall, and continuing the legacy within our new edifice. Whilst upgrading people’s lifestyles, Sambhav tries to maintain the old charm of the city and encourage community building."
                />
                <br />
              </>
            )}

            <P
              className="p-adjust"
              p="While the undertaking does have its constraints, the result is indeed worth tenfold and is a spectacle to set one’s eyes on."
            />
            <br />
            <P
              className="p-adjust"
              p="When consummated, the outcome stands bold, and tall within its surroundings, commanding the sights of onlookers passing by."
            />
          </div>

          <div className="section-3" />
        </div>
        <div className="arrow-header">Architect &amp; Design</div>
      </div>
    );
  }
}

export default AboutUs2;
