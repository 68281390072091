import React, { Component } from "react";
import Background from "../Background/Background";
import H1 from "../H1/H1";
import contactcss from "./contact.css";
import P from "../p/P";
import InputBox from "../inputbox/InputBox";
import fb from "../images/fbk.png";
import insta from "../images/ins.png";
import lnkd from "../images/lnkd.png";
import $ from "jquery";
import { scrollifyy } from "../animation/Animation";
import { submit } from "../scripts/mail";
let data = {};
class Contact extends Component {
  state = {
    Name: "",
    Mobile: "",
    Email: "",
    Message: ""
  };
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange2 = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ Mobile: onlyNums });
  };

  // handleChange5 = event => {
  //   this.setState({
  //     value5: event.target.value
  //   });
  // };

  componentDidMount() {
    $.scrollify.disable();
    $("html").css("overflow-y", "auto");

    scrollifyy("contactus");
  }
  render() {
    const sendDataToScript = e => {
      data.Name = this.state.Name;
      data.MobNumber = this.state.Mobile;
      data.EmailID = this.state.Email;
      data.Message = this.state.Message;

      submit(data);
    };
    return (
      <div className="contact-container">
        <Background />

        <div className="panel-container">
          <div className="info-container">
            <div className="sec-1">
              <H1 h1="Office:" />
              <P
                className="p-adjust"
                p="1301, Lodha Supremus, Senapati Bapat Marg, Lower Parel, Mumbai - 400 013"
              />
            </div>
            <div className="sec-2">
              <H1 h1="Telephone:" />
              <a href="tel:+022-24366582">
                <P className="p-adjust" p="022-24366582" />
              </a>
              <P className="p-adjust" p="022-66666191/2/3/4/5" />
              <a href="tel:+91 77770 79991">
                <P className="p-adjust" p="+91 77770 79991" />
              </a>
            </div>
            <div className="sec-3">
              <H1 h1="Email:" />
              <a href="mailto:info@sambhavgroup.co.in" target="_top">
                <P className="p-adjust" p="info@sambhavgroup.co.in" />
              </a>
            </div>
          </div>

          <div className="input-container">
            <H1 className="h1adjust" h1="Contact Us" />
            <div className="input-box">
              <InputBox
                id="Name"
                placeholder="Name & Surname"
                value={this.state.Name}
                name="Name"
                onChange={this.handleChange}
              />
              {/* <InputBox className="input-adjust" placeholder="Company" /> */}
              <InputBox
                id="MobNumber"
                className="input-adjust"
                placeholder="Telephone"
                value={this.state.Mobile}
                name="Mobile"
                onChange={this.handleChange2}
              />
              <InputBox
                id="EmailID"
                placeholder="Email"
                name="Email"
                value={this.state.Email}
                onChange={this.handleChange}
              />
              {/* <InputBox className="msg-adjust" placeholder="Message" /> */}
              <textarea
                id="Message"
                rows="1"
                cols="50"
                name="Message"
                placeholder="Message"
                value={this.state.Message}
                onChange={this.handleChange}
                className="animated fadeInDown animation"
              />
            </div>
            <button
              class="button animated fadeInDown animation"
              id="disable"
              onClick={sendDataToScript}
            >
              Send
            </button>
            <div />
          </div>
          <div className="social-media animated slideInRight animation">
            <H1 className="h1-adjust" h1="Follow Us" />
            <div className="iconsContainer">
              <div className="social">
                <a
                  href="https://www.facebook.com/Sambhavgroup9"
                  target="_blank"
                >
                  <img src={fb} alt="" />
                </a>
              </div>

              <div className="social">
                <a
                  href="https://www.instagram.com/sambhavgroup"
                  target="_blank"
                >
                  <img src={insta} alt="" />
                </a>
              </div>

              <div className="social">
                <a
                  href="https://www.linkedin.com/company/sambhav-group/"
                  target="_blank"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div className="social">
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </div>
              <div className="social">
                <a
                  href="#"
                  target="_blank"
                >
                  <i class="fab fa-pinterest-p"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
