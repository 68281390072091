import React, { Component } from "react";
import $ from "jquery";
import presscss from "./press.css";
import Background from "../Background/Background";
import news1 from "../images/news1.png";
import news2 from "../images/news2.png";
import Footer from "../footer/Footer";
import News from "../news/News";
let news3 = "https://res.cloudinary.com/dymmjqche/image/upload/v1623858024/New_release/Cover_for_Website_Press_release-01_ju2bta.jpg";
let news4 = "https://res.cloudinary.com/dymmjqche/image/upload/v1623858024/New_release/Cover_for_Website_Press_release-03_rzcdmp.jpg";
let news5 = "https://res.cloudinary.com/dymmjqche/image/upload/v1623858024/New_release/Cover_for_Website_Press_release-02_yrs0zj.jpg";
let news6 = "https://res.cloudinary.com/dymmjqche/image/upload/v1623858024/New_release/Cover_for_Website_Press_release-04_oevqcj.jpg";
let news7 = "https://res.cloudinary.com/dymmjqche/image/upload/v1623858024/New_release/Cover_for_Website_Press_release-05_ygenw1.jpg";
class Press extends Component {
  state = {};

  componentDidMount() {
    // $("html").css("overflow-y", "auto");
  }
  render() {
    return (
      <React.Fragment>
        <div className="pressContainer">
          <Background />
          <div className="panelContainer">
            <div className="newsSection">
              <News
                imgSrc={news3}
                links="https://www.business-standard.com/article/news-ani/sambhav-group-decodes-the-dcpr-2034-plan-118121000658_1.html"
              />
              <News
                imgSrc={news4}
                links="https://www.99acres.com/articles/demand-for-residential-properties-regains-momentum-in-south-mumbai.html"
              />
              <News
                imgSrc={news5}
                links="https://www.businessapac.com/sambhav-group-building-possibilities-real-estate-sector/"
              />
              <News
                imgSrc={news6}
                links="https://www.architectandinteriorsindia.com/"
              />
              <News
                imgSrc={news7}
                links="https://www.mgsarchitecture.in/about-us.html"
              />
              {/* <News
                imgSrc={news1}
                links="https://www.business-standard.com/article/news-ani/sambhav-group-decodes-the-dcpr-2034-plan-118121000658_1.html"
              />

              <News
                imgSrc={news2}
                links="https://www.businessapac.com/sambhav-group-building-possibilities-real-estate-sector/"
              /> */}
            </div>
          </div>
        </div>
        {/* <div className="press-footer">
          <Footer />
        </div> */}
      </React.Fragment>
    );
  }
}

export default Press;
