import React, { Component } from "react";
import Background from "../Background/Background";
import portcss from "./portfolio.css";
import Project from "../Project/Project";
import Footer from "../footer/Footer";
import { connect } from "react-redux";
import { scrollify } from "jquery-scrollify";
import filter from "../images/filter.png";
import { NavLink } from "react-router-dom";
import { scrollifyy } from "../animation/Animation";
import $ from "jquery";
import logo from "../images/logo.png";
let backLogo =
  "https://res.cloudinary.com/dubm0zyx2/image/upload/v1586355658/Sambhav_Logo-01_sibyhu.svg";
let loadingGif = "https://res.cloudinary.com/dymmjqche/image/upload/v1570772587/Sambhav_uak7v7.gif";
class Portfolio extends Component {
  state = {};
  componentDidMount() {
    $(document).scrollTop(0);
    $.scrollify.disable();
    $("html").css("overflow-y", "auto");
    $("#opttt1").css("font-weight", "bold");
    // scrollifyy("portfolio");
    $("#link").css("border-bottom", "1px solid black");

    $(".sublinks").click(function () {
      $(".sublinks").css("border-bottom", "none");

      $(this).css("border-bottom", "1px solid black");
    });
    this.props.activeSubCategories("All");
    $("#option1").css("display", "inline-block");
    $(".drop-contt").css("display", "none");
  }
  componentDidUpdate() {
    $(document).scrollTop(0);
    // scrollifyy("portfolio");
  }
  seeemore() {
    $(".drop-contt").toggle();
    // $(this).css("font-weight", "border");

    $(".drop-contt ul li").click(function () {
      $(".drop-contt ul li").css("font-weight", "normal");
      $(this).css("font-weight", "bold");
    });
  }
  render() {
    const renderProjects = () => {
      return this.props.projects.map((items, index) => {
        if ($(window).width() > 800) {
          if (index < 12) {
            return (
              <NavLink
                to={"/portfolio/" + items.title}
                onClick={() => this.props.projectClick(items.title)}
                activeClassName="activeRoute"
                exact
              >
                <Project
                  key={index}
                  titleImg={items.titleImg}
                  hover={items.hover}
                  title={items.title}
                />
              </NavLink>
            );
          }
        } else {
          return (
            <NavLink
              to={"/portfolio/" + items.title}
              onClick={() => this.props.projectClick(items.title)}
              activeClassName="activeRoute"
              exact
            >
              <Project
                key={index}
                titleImg={items.titleImg}
                hover={items.hover}
                title={items.title}
              />
            </NavLink>
          );
        }
      });
    };
    // const rendersingleProject = () => {
    //   return this.props.projects.map((items, index) => {
    //     if ($(window).width() > 800) {
    //       if (index === 10) {
    //         return (
    //           <NavLink
    //             to={"/portfolio/" + items.title}
    //             onClick={() => this.props.projectClick(items.title)}
    //             activeClassName="activeRoute"
    //             exact
    //           >
    //             <Project
    //               key={index}
    //               titleImg={items.titleImg}
    //               hover={items.hover}
    //               title={items.title}
    //             />
    //           </NavLink>
    //         );
    //       }
    //     }
    //   });
    // };
    const renderSambhavLogo = () => {
      if ($(window).width() > 800) {
        return <div className="logoContainer">
          <div className="logoImage">
            <img src={loadingGif} alt="" />
          </div>
        </div>
      }
    }
    return (
      <div className="portfolio-container">
        <Background />
        <div className="panelContainer">
          {/* <div className="filter-bg">
            Filter:
            {this.props.subcategory.map((item, index) => {
              return (
                <li
                  id="link"
                  className="sublinks"
                  onClick={() => this.props.activeSubCategories(item.title)}
                >
                  {item.title}
                </li>
              );
            })}
          </div> */}

          {/* <div className="mobile-sublinks" onClick={this.seeemore}>
            <img src={filter} className="filter-icon" alt="" />
            <div className="mob-drop-header">
              {this.props.subcategory.map((item, index) => {
                return (
                  <p
                    className="js-content"
                    id={"option" + Number(index + 1)}
                    onClick={() => this.seeemore}
                  >
                    {item.title}
                  </p>
                );
              })}
            </div>
          </div> */}

          {/* <div className="drop-contt">
            <ul>
              {this.props.subcategory.map((item, index) => {
                return (
                  <li
                    className="drop-cont-links"
                    id={"opttt" + Number(index + 1)}
                    onClick={() => this.props.activeSubCategories(item.title)}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div> */}

          <div className="data-container">{renderProjects()}</div>
          <div className="projects">
            <div className="upcomingProjects">
              {/* <img src={backLogo} alt="logo" className="bg-image" /> */}
              <div className="header">Finished Projects</div>
              <div className="projectName">- Ketan Apts, Mazgaon</div>
              <div className="projectName">- Jupiter Towers, Mazgaon</div>
              <div className="projectName">- Darshan Towers, Mazgaon</div>
              <div className="projectName">- Vama, Badlapur</div>
              <div className="projectName">- Sambhav Darshan, Sandhurst Road</div>
            </div>
            {renderSambhavLogo()}
            <div className="upcomingProjects">
              {/* <img src={backLogo} alt="logo" className="bg-image" /> */}
              <div className="header">Forthcoming Projects</div>
              <div className="projectName">- Vishram, Girgaon</div>
              <div className="projectName">- CW, Marine Lines</div>
              <div className="projectName">- S. Bhavan, Marine Lines</div>
              <div className="projectName">- Platinam, Malad (East)</div>
              <div className="projectName">- K, Kalyan</div>
              <div className="projectName">- Eden II, Vile Parle</div>
              <div className="projectName">- Amir, Mazgaon</div>
              {/* <div className="projectName">- Sai Chayya</div> */}
              {/* <div className="projectName">- Express Building, Forjit Street</div> */}
              {/* <div className="projectName">- Allure, Kandivali (East) </div> */}
              {/* <div className="projectName">- Brightland, Bandra (West) </div> */}
              {/* <div className="projectName">- Morar, Gamdevi</div> */}
            </div>
          </div>

          {/* <div className="upcomingProjects"></div> */}

          <div className="port-footer">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    activeSubCategory: state.activeSubCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    projectClick: (name) => dispatch({ type: "projectClick", value: name }),
    activeSubCategories: (name) =>
      dispatch({ type: "activeSubCategories", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
