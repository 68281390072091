import $ from "jquery";
import { directive } from "@babel/types";

export function submit(data) {
  //validation

  const name = data.Name;
  const email = data.EmailID;
  const number = data.MobNumber;
  const message = data.Message;

  if (name.length == 0) {
    alert("Please Enter Your Name");
    return;
  }

  //email = data.EmailID;
  if (email.length == 0) {
    alert("Please enter valid email address");
    return;
  }
  if (validateEmail(email)) {
  } else {
    alert("Invalid Email Address");
    return;
  }
  if (number.length == 0) {
    alert("Please Enter Your Number");
    return;
  }
  if (number.length > 10) {
    alert("Invalid Number");
    return;
  }
  if (message.length == 0) {
    alert("Please Enter Your Message");
    return;
  }
  $("#disable").attr("disabled", true);

  $.ajax({
    url: "/script.php",
    type: "POST",
    data: {
      name: name,
      email: email,
      number: number,
      message: message,
    },
    success: function (res) {
      $("#disable").attr("disabled", false);
      $("#Name").val("");
      $("#EmailID").val("");
      $("#MobNumber").val("");
      $("#Message").val("");
      alert("mail sent");
    },
    error: function (e) {
      $("#disable").attr("disabled", false);

      alert("Error Occurred");
    },
  });
}

function validateEmail(email) {
  var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  if (filter.test(email)) {
    return true;
  } else {
    return false;
  }
}
