import React, { Component } from "react";
import AboutUs1css from "./aboutus1.css";
import Links from "../links/Links";

import Background from "../Background/Background";
import P from "../p/P";
class AboutUs1 extends Component {
  state = {};
  render() {
    return (
      <div className={"aboutus01-container " + this.props.className}>
        <Links activeLink={"Overview"} />
        <div className="panelContainer">
          <div className="section-1" />
          <div className="section-2">
            <P p="Founded in 1988, the Sambhav group brings together modernity, heritage, comfort, and unfathomable creativity in their developments." />
            <br />
            <P p="Sambhav goes beyond just development. It acts as a multi-functional entity responsible for analytics, designing, investment, finance, construction, and management for each of its projects. These attributes have made it possible for the group to capitalize on a range of commercial and residential opportunities." />
            <br />
            <P p="Sambhav’s accomplishments in terms of the acquisition, re-development, and construction have been vast and varied and encompass a considerably large portion of Mumbai." />
            <br />

            <P p="In the recent projects handled, the focus has been on rebuilding the city’s most prominent and oldest buildings. They are transforming these historical edifices to accommodate for a new generation’s comfortable lifestyle." />
            <br />

            <P p="This is why Sambhav has been able to thrive working on around 3 million square feet of land while providing residential spaces and thus, building possibilities." />
          </div>
          <div className="section-3" />
        </div>
        <div className="arrow-header">Presevation &amp; Construction</div>
      </div>
    );
  }
}

export default AboutUs1;
