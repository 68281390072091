import React, { Component } from "react";
import "./page3.css";
import $ from "jquery";
import { connect } from "react-redux";
import Footer from "../footer/Footer";

class Page3 extends Component {
  state = {};

  componentDidMount() {
    $(".renderMapThumb img:first-child").addClass("activeThumb");

    $(".renderMapThumb img").click(function(event) {
      $(".activeThumb").removeClass("activeThumb");

      $(this).addClass("activeThumb");
    });
  }

  render() {
    const renderMap = () => {
      return this.props.projects.map((item, index) => {
        if (item.title === this.props.activeProject) {
          return item.map.map((map, index) => {
            if (map.name === this.props.activeThumb) {
              return <img src={map.img} alt="" className="ani-show" />;
            }
          });
        }
      });
    };

    const renderMapThumb = () => {
      return this.props.projects.map((item, index) => {
        if (item.title === this.props.activeProject) {
          return item.map.map((map, index) => {
            return (
              <img
                src={map.mapThumb}
                alt=""
                onClick={() => this.props.thumbClick(map.name)}
              />
            );
          });
        }
      });
    };
    return (
      <div className={"page3Container " + this.props.className}>
        <div className="renderMap">{renderMap()}</div>
        <div className="renderMapThumb">{renderMapThumb()}</div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    // activeProject: state.activeProject,
    activeMap: state.activeMap,
    activeThumb: state.activeThumb
  };
};

const mapDispatchToProps = dispatch => {
  return {
    thumbClick: name => dispatch({ type: "thumbClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page3);
