import React, { Component } from "react";
import { connect } from "react-redux";
import menu from "../images/menu.png";
import "./projectpage.css";
import Background from "../Background/Background";
import Page1 from "../page1/Page1";
import Page2 from "../page2/Page2";
import Page3 from "../page3/Page3";
import $ from "jquery";
import { scrollify } from "jquery-scrollify";
import Menu from "../menu/Menu";
import arrow from "../images/arrow.png";
// import Background from "../Background/Background";
import { NavLink } from "react-router-dom";

class ProjectPage extends Component {
  state = {};
  componentDidMount() {
    $(document).scrollTop(0);
    if ($(window).width() <= 800) {
      $.scrollify.disable();
    } else {
      $.scrollify.enable();
      $(function () {
        $.scrollify({
          section: ".scroll"
        });
      });
    }
  }
  menuToggle() {
    $(".menu-section").css("opacity", "1");
    $(".menu-section").removeClass("animated fadeOutRight animation");
    $(".menu-section").addClass("animated slideInRight animation");
    setTimeout(function () {
      $(".menu-section").css("display", "block");
    }, 100);
  }
  render() {
    return (
      <div className="ProjectPageContainer">
        <NavLink to="/portfolio">
          <div className="backButton">
            <img src={arrow} alt="" />
            <p>Portfolio</p>
          </div>
        </NavLink>

        <div className="menu-section">
          <Menu />
        </div>
        <div className="menu-container" onClick={() => this.menuToggle()}>
          <img className="menu" src={menu} />
        </div>
        <Page1 className="scroll" activeProject={this.props.activeCategory} />
        <Page2 className="scroll" style={{ paddingBottom: "80px" }} activeProject={this.props.activeCategory} />
        <Page3 className="scroll" activeProject={this.props.activeCategory} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subcategory: state.subcategory,
    projects: state.projects,
    activeProject: state.activeProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectClick: name => dispatch({ type: "projectClick", value: name }),
    next: () => dispatch({ type: "next" }),
    prev: () => dispatch({ type: "prev" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
