import React, { Component } from "react";
import AboutUs1css from "./aboutus5.css";
import team from "../images/team.png";
import Background from "../Background/Background";
import Links from "../links/Links";
import { nextPage } from "../animation/Animation";
import prev from "../images/prev.png";
import $ from "jquery";
import { connect } from "react-redux";
// import P from "../p/P";
let vinay =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1567495084/Vinay_ogqjqd.jpg";
let hitest =
  "https://res.cloudinary.com/dymmjqche/image/upload/v1566371868/hitesh_jain_1_zg3zhl.jpg";
class AboutUs5 extends Component {
  state = {};
  componentDidMount() {
    $(".teamHeader p:first-child").addClass("activeTeam");

    // if ($(window).width() < 800) {
    //   $(".team-image img").click(function() {
    //     // $(this).css("height", 194.5 * (100 / 375) + "vw");
    //     // $(this).css("width", 140.5 * (100 / 375) + "vw");
    //     $(".teamHeader p").removeClass("activeTeam");
    //     $(this).addClass("activeTeam");
    //   });
    // }
    $(".teamHeader p").click(function () {
      $(".teamHeader p").removeClass("activeTeam");
      $(this).addClass("activeTeam");
    });
  }
  teamToggle(name) {
    $(".activeTeam").css("display", "none");
    $("." + name).css("display", "block");
  }
  render() {
    return (
      <div className={"aboutus05-container " + this.props.className}>
        <div className="backSection" onClick={() => nextPage("04")}>
          <img src={prev} alt="" className="backBtn" />
          Back
        </div>
        {/* <Background
          title="Core Team"
          bgLine={false}
          downArrow={false}
          className="textAdjust"
        /> */}
        <Links activeLink={"Core Team"} />

        <div className="panelContainer">
          <div className="title-line1 animated fadeInDown animation" />
          <div className="title-line2 animated fadeInDown animation" />
          <div className="teamContainer">
            <div className="teamHeader">
              {this.props.team.map(item => {
                return (
                  <p onClick={() => this.props.teamClick(item.title)}>
                    {item.title}
                  </p>
                );
              })}
            </div>
            <div className="teamContent">
              {this.props.team.map(item => {
                if (this.props.activeTeam === item.title) {
                  return item.desc.map(desc => {
                    return (
                      <p className="animated fadeInDown animation">{desc}</p>
                    );
                  });
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    team: state.team,
    activeTeam: state.activeTeam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    teamClick: name => dispatch({ type: "teamClick", value: name })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs5);
