import React, { Component } from "react";
import { connect } from "react-redux";
// import { Spring } from "react-spring/renderprops";
import { Spring } from "react-spring";
import Loading from "../loading/Loading";
import Home from "../home/Home";

class Home2 extends Component {
  state = {};
  componentDidMount() {
    setTimeout(() => this.props.setLoading(), 3000);
  }

  render() {
    if (this.props.loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {props => (
          <div style={props}>
            <Home />
          </div>
        )}
      </Spring>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch({ type: "set_loading" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home2);
